import Swal from "sweetalert2"

export default function useDelete(deleteFunction, successMessage, fetchDataFunction) {
    const confirmDelete = (item) => {
        Swal.fire({
            title: "Bist du sicher?",
            text: "Wenn Sie es einmal gelöscht haben, können Sie es nicht mehr zurückbekommen!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Ja",
            cancelButtonText: "Stornierung",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteFunction(item).then((res) => {
                    console.log(res);
                    Swal.fire({
                        title: "Gelöscht!",
                        text: successMessage,
                        icon: "success",
                        confirmButtonText: "OK",
                    });
                    fetchDataFunction();
                });
            }
        });
    };

    return {
        confirmDelete
    };
}
