<script setup>
import { computed, onMounted, ref, defineEmits } from "vue"

import Lottie from "@/components/widgets/lottie.vue"
import animationData from "@/components/widgets/msoeawqm.json"

import store from "../../state/store"
import useServices from "../../use/useServices"

const services = computed(() => store.state.services)

const { getServices } = useServices()

const emit = defineEmits(["clickEdit"])

onMounted(() => {
  getServices()
})

const defaultOptions = ref({
  animationData: animationData,
})

const editService = (service) => {
  emit("clickEdit", service)
}
</script>

<template>
  <BCardBody>
    <div>
      <div class="table-responsive table-card mb-1">
        <table class="table align-middle" id="serviceTable">
          <thead class="table-light text-muted">
            <tr>
              <th class="sort" data-sort="service_column_image">Bild</th>
              <th class="sort" data-sort="service_column_title">
                Spaltentitel
              </th>
              <th class="sort" data-sort="page_title">Seitentitel</th>
              <th class="sort" data-sort="priority">Priorität</th>
              <th class="sort" data-sort="action">Der Betrieb</th>
            </tr>
          </thead>

          <tbody class="list" v-if="services.loading || !services.data.length">
            <tr>
              <td colspan="8">
                <div class="text-center">
                  <BSpinner
                    v-if="services.loading"
                    variant="dark"
                    style="width: 3rem; height: 3rem"
                  ></BSpinner>
                  <div v-else>
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">Es gibt keinen Service!</h5>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody class="list" v-else>
            <tr v-for="(service, index) of services.data" :key="index">
              <td class="service-column-image">
                <div class="avatar-sm bg-light rounded p-1">
                  <img
                    :src="service.service_column_image"
                    :alt="service.service_column_title_de"
                    class="img-fluid d-block"
                  />
                </div>
              </td>
              <td class="service-column-title">
                {{ service.service_column_title_de }}
              </td>
              <td class="page-title">{{ service.page_title_de }}</td>
              <td class="priority">{{ service.priority }}</td>
              <td>
                <ul class="list-inline hstack gap-2 mb-0">
                  <li
                    class="list-inline-item edit"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="Dienst bearbeiten"
                  >
                    <BLink
                      variant="text-primary"
                      class="d-inline-block edit-item-btn"
                      @click="editService(service)"
                    >
                      <i class="ri-pencil-fill fs-16"></i>
                    </BLink>
                  </li>

                  <li
                    class="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="Elemente der Serviceliste"
                  >
                    <RouterLink
                      :to="{
                        name: 'service.list',
                        params: { serviceId: service.id },
                      }"
                      class="text-primary d-inline-block"
                      target="_blank"
                      ><i class="ri-list-unordered fs-18"></i>
                    </RouterLink>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </BCardBody>
</template>
