import home from '../views/home/index.vue'
import services from '../views/services/index.vue'
import serviceList from '../views/services/service-list.vue'
import gain from '../views/gain/index.vue'
import about from '../views/about/index.vue'
import aboutSectionList from '../views/about/section-list.vue'
import settings from '../views/settings/index.vue'
import profile from '../views/settings/Profile.vue'

export default [

  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      title: "Verwaltung der Startseite",
      authRequired: true,
      isAdmin: true,
    }
  },

  {
    path: '/services',
    name: 'services',
    component: services,
    meta: {
      title: "Dienstleistungen",
      authRequired: true,
      isAdmin: true,
    }
  },

  {
    path: '/services/list/:serviceId',
    name: 'service.list',
    component: serviceList,
    meta: {
      title: "Elemente der Serviceliste",
      authRequired: true,
      isAdmin: true,
      props: true,
    }
  },

  {
    path: '/gain',
    name: 'gain',
    component: gain,
    meta: {
      title: "Ihr Gewinn",
      authRequired: true,
      isAdmin: true,
    }
  },

  {
    path: '/about-us',
    name: 'about',
    component: about,
    meta: {
      title: "Über uns",
      authRequired: true,
      isAdmin: true,
    }
  },

  {
    path: '/about-us/sections/list/:sectionId',
    name: 'about.section.list',
    component: aboutSectionList,
    meta: {
      title: "Abschnittsliste „Über uns“.",
      authRequired: true,
      isAdmin: true,
      props: true,
    }
  },


  {
    path: '/settings',
    name: 'settings',
    component: settings,
    meta: {
      title: "Einstellungen",
      authRequired: true,
      isAdmin: true,
    }
  },

  {
    path: '/profile',
    name: 'profile',
    component: profile,
    meta: {
      title: "Profil",
      authRequired: true,
      isAdmin: true,
    }
  },






  {
    path: '/login',
    name: 'login',
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login Seite",
      guestRequired: true
    }
  },

  {
    path: '/reset-password',
    name: 'reset.password',
    component: () => import("../views/account/reset-password.vue"),
    meta: {
      title: "Passwort zurücksetzen",
      guestRequired: true
    }
  },

  {
    path: '/create-password',
    name: 'create.password',
    component: () => import("../views/account/create-password.vue"),
    meta: {
      title: "Passwort erstellen",
      guestRequired: true
    }
  },


];