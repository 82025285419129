<script setup>
import useServices from "../../use/useServices"
import { onUpdated, defineProps, ref, defineEmits } from "vue"

const props = defineProps({
  modelValue: Boolean,
  service: {
    required: true,
    type: Object,
  },
})

const serviceData = ref({
  id: props.service.id,
  service_column_title_en: props.service.service_column_title_en,
  service_column_title_de: props.service.service_column_title_de,
  page_title_en: props.service.page_title_en,
  page_title_de: props.service.page_title_de,
  top_text_en: props.service.top_text_en,
  top_text_de: props.service.top_text_de,
  bottom_text_en: props.service.bottom_text_en,
  bottom_text_de: props.service.bottom_text_de,
  priority: props.service.priority,
  service_column_image: props.service.service_column_image,
  service_page_image: props.service.service_page_image,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, errors, onSubmit, closeModal } = useServices(
  props,
  emit,
  serviceData
)

onUpdated(() => {
  serviceData.value = {
    id: props.service.id,
    service_column_title_en: props.service.service_column_title_en,
    service_column_title_de: props.service.service_column_title_de,
    page_title_en: props.service.page_title_en,
    page_title_de: props.service.page_title_de,
    top_text_en: props.service.top_text_en,
    top_text_de: props.service.top_text_de,
    bottom_text_en: props.service.bottom_text_en,
    bottom_text_de: props.service.bottom_text_de,
    priority: props.service.priority,
    service_column_image: props.service.service_column_image,
    service_page_image: props.service.service_page_image,
  }
})

const onServiceColumnImageChange = (event) => {
  serviceData.value.service_column_image = event.target.files[0]
}

const onServicePageImageChange = (event) => {
  serviceData.value.service_page_image = event.target.files[0]
}
</script>

<template>
  <!-- add service modal  -->
  <BModal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="Dienst hinzufügen"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    size="lg"
    centered
    no-close-on-backdrop
  >
    <BOverlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <BCard>
        <BForm
          id="addform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
          enctype="multipart/form-data"
        >
          <div class="row">
            <div class="col-6">
              <div class="mb-3">
                <label for="service_column_title_en" class="form-label"
                  >Titel der Servicespalte (en)</label
                >
                <input
                  type="text"
                  id="service_column_title_en"
                  class="form-control"
                  placeholder="Titel der Servicespalte (en)"
                  v-model="serviceData.service_column_title_en"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.service_column_title_en"
                  >
                    <span>{{ errors.service_column_title_en[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="service_column_title_de" class="form-label"
                  >Titel der Servicespalte (de)</label
                >
                <input
                  type="text"
                  id="service_column_title_de"
                  class="form-control"
                  placeholder="Titel der Servicespalte (de)"
                  v-model="serviceData.service_column_title_de"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.service_column_title_de"
                  >
                    <span>{{ errors.service_column_title_de[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="mb-3">
                <label for="page_title_en" class="form-label"
                  >Titel der Serviceseite (en)</label
                >
                <input
                  type="text"
                  id="page_title_en"
                  class="form-control"
                  placeholder="Titel der Serviceseite (en)"
                  v-model="serviceData.page_title_en"
                />
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.page_title_en">
                    <span>{{ errors.page_title_en[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="page_title_de" class="form-label"
                  >Titel der Serviceseite (de)</label
                >
                <input
                  type="text"
                  id="page_title_de"
                  class="form-control"
                  placeholder="Titel der Serviceseite (de)"
                  v-model="serviceData.page_title_de"
                />
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.page_title_de">
                    <span>{{ errors.page_title_de[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-3">
                <label for="top_text_en" class="form-label"
                  >Text oben (en)</label
                >
                <textarea
                  class="form-control"
                  id="top_text_en"
                  placeholder="Text oben (en)"
                  rows="2"
                  v-model="serviceData.top_text_en"
                ></textarea>
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.top_text_en">
                    <span>{{ errors.top_text_en[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label for="top_text_de" class="form-label"
                  >Text oben (de)</label
                >
                <textarea
                  class="form-control"
                  id="top_text_de"
                  placeholder="Text oben (de)"
                  rows="2"
                  v-model="serviceData.top_text_de"
                ></textarea>
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.top_text_de">
                    <span>{{ errors.top_text_de[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-3">
                <label for="bottom_text_en" class="form-label"
                  >Unterer Text (en)</label
                >
                <textarea
                  class="form-control"
                  id="bottom_text_en"
                  placeholder="Unterer Text (en)"
                  rows="2"
                  v-model="serviceData.bottom_text_en"
                ></textarea>
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.bottom_text_en">
                    <span>{{ errors.bottom_text_en[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label for="bottom_text_de" class="form-label"
                  >Unterer Text (de)</label
                >
                <textarea
                  class="form-control"
                  id="bottom_text_de"
                  placeholder="Unterer Text (de)"
                  rows="2"
                  v-model="serviceData.bottom_text_de"
                ></textarea>
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.bottom_text_de">
                    <span>{{ errors.bottom_text_de[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-3">
                <label for="priority" class="form-label">Priorität</label>
                <input
                  type="number"
                  id="priority"
                  class="form-control"
                  placeholder="Priorität"
                  v-model="serviceData.priority"
                />
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.priority">
                    <span>{{ errors.priority[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="mb-3">
                <label for="service_column_image" class="form-label"
                  >Bild der Servicesäule</label
                >
                <input
                  class="form-control"
                  type="file"
                  id="service_column_image"
                  @change="onServiceColumnImageChange"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.service_column_image"
                  >
                    <span>{{ errors.service_column_image[0] }}</span>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-6">
              <div class="mb-3">
                <label for="service_page_image" class="form-label"
                  >Bild der Serviceseite</label
                >
                <input
                  class="form-control"
                  type="file"
                  id="service_page_image"
                  @change="onServicePageImageChange"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.service_page_image"
                  >
                    <span>{{ errors.service_page_image[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <BButton
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
            schließen
            </BButton>
            <BButton type="submit" variant="success"
              > Dienst hinzufügen </BButton
            >
          </div>
        </BForm>
      </BCard>
    </BOverlay>
  </BModal>
</template>
