import { ref } from "vue";
import { useToast } from "vue-toastification"

import store from "../state/store"

export default function useAbout(aboutInfo) {

    // Get toast interface
    const toast = useToast()

    const loading = ref(false)
    const errors = ref({})

    const onSubmit = () => {
        loading.value = true
        store
            .dispatch("updateAboutInfo", aboutInfo.value)
            .then((response) => {
                loading.value = false
                if (response.status === 200) {
                    toast.success("Erfolgreich aktualisiert.", {
                        timeout: 3000,
                    })
                    store.dispatch("getAboutInfo")
                }
            })
            .catch((err) => {
                loading.value = false
                errors.value = err.response.data.errors
            })
    }

    return {
        loading,
        errors,
        onSubmit,
    }
}