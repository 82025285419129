<script>
import NavBar from "@/components/nav-bar"
import RightBar from "@/components/right-bar"
import Footer from "@/components/footer"

export default {
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path)
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="' + ele + '"]')

          if (a) {
            a.classList.add("active")
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown")
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show")
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              )
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              )
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show")
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active")
              }
            }
          }
        }
      }, 1000)
    },
  },
  mounted() {
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse")
      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation()
          let closestCollapse = collapse.parentElement.closest(".collapse")
          if (closestCollapse) {
            let siblingCollapses = closestCollapse.querySelectorAll(".collapse")
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                siblingCollapse.classList.remove("show")
                siblingCollapse.parentElement.firstChild.setAttribute(
                  "aria-expanded",
                  "false"
                )
              }
            })
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = []
              let sibling = elem.parentNode.firstChild
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling)
                }
                sibling = sibling.nextSibling
              }
              return siblings
            }
            let siblings = getSiblings(collapse.parentElement)
            siblings.forEach((item) => {
              if (item.childNodes.length > 2) {
                item.firstElementChild.setAttribute("aria-expanded", "false")
                item.firstElementChild.classList.remove("active")
              }
              let ids = item.querySelectorAll("*[id]")
              ids.forEach((item1) => {
                item1.classList.remove("show")
                item1.parentElement.firstChild.setAttribute(
                  "aria-expanded",
                  "false"
                )
                item1.parentElement.firstChild.classList.remove("active")
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a")
                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false")
                  })
                }
              })
            })
          }
        })
        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation()
          let childCollapses = collapse.querySelectorAll(".collapse")
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse
            childCollapseInstance.classList.remove("show")
            childCollapseInstance.parentElement.firstChild.setAttribute(
              "aria-expanded",
              "false"
            )
          })
        })
      })
    }
  },
  components: {
    NavBar,
    RightBar,
    Footer,
  },
}
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <NavBar />
      <!-- ========== App Menu ========== -->
      <div class="app-menu navbar-menu">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- Dark Logo-->
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt="" height="38" />
            </span>
          </router-link>
          <!-- Light Logo-->
          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt="" height="38" />
            </span>
          </router-link>
          <button
            type="button"
            class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i class="ri-record-circle-line"></i>
          </button>
        </div>
        <div id="scrollbar">
          <BContainer fluid>
            <ul class="navbar-nav h-100" id="navbar-nav">
              <li class="nav-item">
                <router-link
                  class="nav-link menu-link"
                  href="#"
                  :to="{ name: 'home' }"
                >
                  <i class="mdi mdi-speedometer"></i>
                  <span data-key="t-home">{{ $t("t-home") }}</span>
                </router-link>
              </li>
              <!-- end Home Menu -->

              <li class="nav-item">
                <router-link
                  class="nav-link menu-link"
                  href="#"
                  :to="{ name: 'services' }"
                >
                  <i class="mdi mdi-sticker-text-outline"></i>
                  <span data-key="t-services">{{ $t("t-services") }}</span>
                </router-link>
              </li>
              <!-- end Services Menu -->

              <li class="nav-item">
                <router-link
                  class="nav-link menu-link"
                  href="#"
                  :to="{ name: 'gain' }"
                >
                  <i class="mdi mdi-trending-up"></i>
                  <span data-key="t-your-gain">{{ $t("t-your-gain") }}</span>
                </router-link>
              </li>
              <!-- end Gain Menu -->

              <li class="nav-item">
                <router-link
                  class="nav-link menu-link"
                  href="#"
                  :to="{ name: 'about' }"
                >
                  <i class="mdi mdi-account-group-outline"></i>
                  <span data-key="t-about-us">{{ $t("t-about-us") }}</span>
                </router-link>
              </li>
              <!-- end Gain Menu -->

              <li class="nav-item">
                <router-link
                  class="nav-link menu-link"
                  href="#"
                  :to="{ name: 'settings' }"
                >
                  <i class="mdi mdi-cog-outline"></i>
                  <span data-key="t-settings">{{ $t("t-settings") }}</span>
                </router-link>
              </li>
              <!-- end Settings Menu -->
            </ul>
          </BContainer>
          <!-- Sidebar -->
        </div>
        <!-- Left Sidebar End -->
        <!-- Vertical Overlay-->
        <div class="vertical-overlay"></div>
      </div>
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="main-content">
        <div class="page-content">
          <!-- Start Content-->
          <BContainer fluid>
            <slot />
          </BContainer>
        </div>
        <Footer />
      </div>
      <RightBar />
    </div>
  </div>
</template>
