<script setup>
import { defineProps, ref, watch, onMounted, computed } from "vue"

import store from "../../state/store"
import useGainListItems from "../../use/useGainListItems"

const props = defineProps({
  gainId: Number,
  listItem: {
    required: true,
    type: Object,
  },
})

const listItemData = ref({
  id: props.listItem.id,
  gain_id: props.gainId,
  item_title_en: props.listItem.item_title_en,
  item_title_de: props.listItem.item_title_de,
  item_description_en: props.listItem.item_description_en,
  item_description_de: props.listItem.item_description_de,
})

watch(
  () => props.gainId,
  (newValue) => {
    listItemData.value.gain_id = newValue
  }
)

const { loading, errors, onSubmit, getGainListItems, deleteGainListItem } =
  useGainListItems({
    listItem: listItemData,
  })

const gainListItems = computed(() => store.state.gainListItems)

onMounted(() => {
  getGainListItems()
})

function editGainListItem(listItem) {
  store.dispatch("getGainListItem", listItem.id).then(({ data }) => {
    listItemData.value = data.data
  })
}
</script>

<template>
  <BOverlay
    variant="white"
    opacity="0.75"
    blur="2px"
    :show="loading"
    spinner-variant="success"
    rounded="sm"
  >
    <form autocomplete="off" @submit.prevent="onSubmit">
      <input type="hidden" v-model="listItemData.gain_id" />

      <div class="row">
        <div class="col-6">
          <div class="mb-3">
            <label for="item_title_en" class="form-label"
              >Titel des Listenelements (en)</label
            >
            <input
              type="text"
              id="item_title_en"
              class="form-control"
              placeholder="Titel des Listenelements (en)"
              v-model="listItemData.item_title_en"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.item_title_en">
                <span>{{ errors.item_title_en[0] }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="item_title_de" class="form-label"
              >Titel des Listenelements (de)</label
            >
            <input
              type="text"
              id="item_title_de"
              class="form-control"
              placeholder="Titel des Listenelements (de)"
              v-model="listItemData.item_title_de"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.item_title_de">
                <span>{{ errors.item_title_de[0] }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label for="item_description_en" class="form-label"
              >Beschreibung des Listenelements (en)</label
            >
            <textarea
              class="form-control"
              id="item_description_en"
              placeholder="Beschreibung des Listenelements (en)"
              rows="2"
              v-model="listItemData.item_description_en"
            ></textarea>
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.item_description_en">
                <span>{{ errors.item_description_en[0] }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="mb-3">
            <label for="item_description_de" class="form-label"
              >Beschreibung des Listenelements (de)</label
            >
            <textarea
              class="form-control"
              id="item_description_de"
              placeholder="Beschreibung des Listenelements (de)"
              rows="2"
              v-model="listItemData.item_description_de"
            ></textarea>
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.item_description_de">
                <span>{{ errors.item_description_de[0] }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="hstack gap-2 justify-content-end">
            <BButton type="submit" variant="primary">
              {{ listItemData.id ? "Bearbeiten" : "hinzufügen" }}
            </BButton>
          </div>
        </div>
      </div>
    </form>
    <div class="mt-4 mb-3 border-bottom pb-2">
      <h5 class="card-title">Gain List Items</h5>
    </div>
    <div
      v-if="gainListItems.loading || !gainListItems.data.length"
      class="text-center"
    >
      <BSpinner
        v-if="gainListItems.loading"
        variant="dark"
        style="width: 3rem; height: 3rem"
      ></BSpinner>
    </div>
    <div
      v-else
      v-for="(listItem, index) of gainListItems.data"
      :key="index"
      class="d-flex align-items-center mb-3"
    >
      <div class="flex-shrink-0 avatar-sm">
        <div class="avatar-title bg-light text-primary rounded-3 fs-18 shadow">
          <i class="ri-checkbox-line"></i>
        </div>
      </div>
      <div class="flex-grow-1 ms-3">
        <h6>{{ listItem.item_title_de }}</h6>
        <p class="text-muted mb-0">
          {{ listItem.item_description_de }}
        </p>
      </div>
      <div class="ms-3 ms-lg-2">
        <ul class="list-inline hstack gap-2 mb-0">
          <li
            class="list-inline-item edit"
            data-bs-toggle="tooltip"
            data-bs-trigger="hover"
            data-bs-placement="top"
            title="Bearbeiten"
          >
            <BLink
              variant="text-primary"
              class="d-inline-block edit-item-btn"
              @click="editGainListItem(listItem)"
            >
              <i class="ri-pencil-fill fs-16"></i>
            </BLink>
          </li>
          <li
            class="list-inline-item"
            data-bs-toggle="tooltip"
            data-bs-trigger="hover"
            data-bs-placement="top"
            title="löschen"
          >
            <BLink
              class="text-danger d-inline-block remove-item-btn"
              @click="deleteGainListItem(listItem)"
            >
              <i class="ri-delete-bin-5-fill fs-16"></i>
            </BLink>
          </li>
        </ul>
      </div>
    </div>
  </BOverlay>
</template>
