import axiosClient from "../../axios";


export async function login({ commit }, data) {
    return await axiosClient.post('/login', data)
        .then(({ data }) => {
            commit('setUser', data.user);
            commit('setToken', data.token)
            return data;
        })
}

export async function resetPassword(_, data) {
    return await axiosClient.post('/forgot-password', data)
        .then(({ data }) => {
            return data;
        })
}

export async function createPassword(_, data) {
    return await axiosClient.post('/reset-password', data)
        .then(({ data }) => {
            return data;
        })
}

export async function logout({ commit }) {
    return await axiosClient.post('/logout')
        .then((response) => {
            commit('setToken', null)
            commit('setUser', null);

            return response
        })
}
