<script setup>
import { computed, ref, onMounted } from "vue"
import store from "../../state/store"
import useOurTeam from "../../use/useOurTeam"
import OurTeamModal from "./our-team-modal.vue"
import OurTeamEditModal from "./our-team-edit-modal.vue"

const team = computed(() => store.state.team)

const { getTeamMembers } = useOurTeam()

const DEFAULT_TEAM = {
  id: "",
  name_en: "",
  name_de: "",
  position_en: "",
  position_de: "",
  description_en: "",
  description_de: "",
  status: "",
  team_image: "",
}

const teamModel = ref({ ...DEFAULT_TEAM })

const showOurTeamModal = ref(false)
const showOurTeamEditModal = ref(false)

const showAddNewModal = () => {
  showOurTeamModal.value = true
}

const showEditModal = () => {
  showOurTeamEditModal.value = true
}

onMounted(() => {
  getTeamMembers()
})

function editTeam(team) {
  console.log(team)
  store.dispatch("getTeamMember", team.id).then(({ data }) => {
    teamModel.value = data.data

    showEditModal()
  })
}

const onModalClose = () => {
  teamModel.value = { ...DEFAULT_TEAM }
}
</script>

<template>
  <BRow>
    <BCol lg="12">
      <BCard no-body id="teamList">
        <BCardHeader class="border-bottom-dashed">
          <BRow class="g-4 align-items-center">
            <BCol sm>
              <div>
                <h5 class="card-title mb-0">Teammitglieder</h5>
              </div>
            </BCol>
            <BCol sm="auto">
              <div class="d-flex flex-wrap align-items-start gap-2">
                <BButton
                  type="button"
                  variant="success"
                  class="add-btn me-1"
                  id="create-btn"
                  @click="showAddNewModal()"
                >
                  <i class="ri-add-line align-bottom me-1"></i> Neues Mitglied hinzufügen
                </BButton>
              </div>
            </BCol>
          </BRow>
        </BCardHeader>

        <BCardBody>
          <div>
            <div class="table-responsive table-card mb-1">
              <table class="table align-middle" id="teamTable">
                <thead class="table-light text-muted">
                  <tr>
                    <th data-sort="team_image">Bild</th>
                    <th data-sort="name">Name</th>
                    <th data-sort="position">Position</th>
                    <th data-sort="description">
                      Beschreibung
                    </th>
                    <th data-sort="action">Der Betrieb</th>
                  </tr>
                </thead>

                <tbody
                  class="list"
                  v-if="team.loading || !team.data.length"
                >
                  <tr>
                    <td colspan="8">
                      <div class="text-center">
                        <BSpinner
                          v-if="team.loading"
                          variant="dark"
                          style="width: 3rem; height: 3rem"
                        ></BSpinner>
                        <div v-else>
                          <lottie
                            class="avatar-xl"
                            colors="primary:#121331,secondary:#08a88a"
                            :options="defaultOptions"
                            :height="75"
                            :width="75"
                          />
                          <h5 class="mt-2">Es gibt keinen Abschnitt!</h5>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody class="list" v-else>
                  <tr
                    v-for="(member, index) of team.data"
                    :key="index"
                  >
                    <td class="team-image">
                      <div class="avatar-sm bg-light rounded p-1">
                        <img
                          :src="member.team_image"
                          :alt="member.name_de"
                          class="img-fluid d-block"
                        />
                      </div>
                    </td>
                    <td class="member-name">
                      {{ member.name_de }}
                    </td>
                    <td class="position">{{ member.position_de }}</td>
                    <td class="description">
                      {{ member.description_de }}
                    </td>
                    
                    <td>
                      <ul class="list-inline hstack gap-2 mb-0">
                        <li
                          class="list-inline-item edit"
                          data-bs-toggle="tooltip"
                          data-bs-trigger="hover"
                          data-bs-placement="top"
                          title="Abschnitt bearbeiten"
                        >
                          <BLink
                            variant="text-primary"
                            class="d-inline-block edit-item-btn"
                            @click="editTeam(member)"
                          >
                            <i class="ri-pencil-fill fs-16"></i>
                          </BLink>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </BCardBody>
      </BCard>
    </BCol>
  </BRow>

  <OurTeamModal
    v-model="showOurTeamModal"
    :team="teamModel"
    @close="onModalClose"
  />

  <OurTeamEditModal
    v-model="showOurTeamEditModal"
    :team="teamModel"
    @close="onModalClose"
  />
</template>
