<script setup>
import { onMounted, computed } from "vue"
import router from "../router"
import store from "../state/store"

const toggleHamburgerMenu = () => {
  var windowSize = document.documentElement.clientWidth
  let layoutType = document.documentElement.getAttribute("data-layout")
  let visiblilityType = document.documentElement.getAttribute(
    "data-sidebar-visibility"
  )

  document.documentElement.setAttribute("data-sidebar-visibility", "show")

  if (windowSize > 767)
    document.querySelector(".hamburger-icon").classList.toggle("open")

  //For collapse horizontal menu
  if (document.documentElement.getAttribute("data-layout") === "horizontal") {
    document.body.classList.contains("menu")
      ? document.body.classList.remove("menu")
      : document.body.classList.add("menu")
  }

  //For collapse vertical menu
  if (
    visiblilityType === "show" &&
    (layoutType === "vertical" || layoutType === "semibox")
  ) {
    if (windowSize < 1025 && windowSize > 767) {
      document.body.classList.remove("vertical-sidebar-enable")
      document.documentElement.getAttribute("data-sidebar-size") == "sm"
        ? document.documentElement.setAttribute("data-sidebar-size", "")
        : document.documentElement.setAttribute("data-sidebar-size", "sm")
    } else if (windowSize > 1025) {
      document.body.classList.remove("vertical-sidebar-enable")
      document.documentElement.getAttribute("data-sidebar-size") == "lg"
        ? document.documentElement.setAttribute("data-sidebar-size", "sm")
        : document.documentElement.setAttribute("data-sidebar-size", "lg")
    } else if (windowSize <= 767) {
      document.body.classList.add("vertical-sidebar-enable")
      document.documentElement.setAttribute("data-sidebar-size", "lg")
    }
  }

  //Two column menu
  if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
    document.body.classList.contains("twocolumn-panel")
      ? document.body.classList.remove("twocolumn-panel")
      : document.body.classList.add("twocolumn-panel")
  }
}

const initTopbarComponents = () => {
  // notification messages
  if (document.getElementsByClassName("notification-check")) {
    Array.from(document.querySelectorAll(".notification-check input")).forEach(
      function (element) {
        element.addEventListener("click", function (el) {
          el.target.closest(".notification-item").classList.toggle("active")
        })
      }
    )
  }
}

const handleScroll = () => {
  var pageTopbar = document.getElementById("page-topbar")
  if (pageTopbar) {
    document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50
      ? pageTopbar.classList.add("topbar-shadow")
      : pageTopbar.classList.remove("topbar-shadow")
  }
}

const currentUser = computed(() => store.state.user.data)

const logout = () => {
  store.dispatch("logout").then(() => {
    router.push({ name: "login" })
  })
}

onMounted(() => {
  document.addEventListener("scroll", handleScroll)

  if (document.getElementById("topnav-hamburger-icon")) {
    document
      .getElementById("topnav-hamburger-icon")
      .addEventListener("click", toggleHamburgerMenu)
  }

  initTopbarComponents()
})
</script>

<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-dark.png" alt="" height="38" />
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-light.png" alt="" height="38" />
              </span>
            </router-link>
          </div>

          <button
            type="button"
            class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none"
            id="topnav-hamburger-icon"
          >
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
        </div>

        <div class="d-flex align-items-center">

          <BDropdown
            variant="link"
            class="ms-sm-3 header-item topbar-user"
            toggle-class="rounded-circle arrow-none  shadow-none"
            menu-class="dropdown-menu-end"
            :offset="{ alignmentAxis: -14, crossAxis: 0, mainAxis: 0 }"
          >
            <template #button-content>
              <span class="d-flex align-items-center">
                <img
                  class="rounded-circle header-profile-user"
                  src="@/assets/images/users/avatar-1.jpg"
                  alt="Header Avatar"
                />
                <span class="text-start ms-xl-2">
                  <span
                    class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"
                    >{{ currentUser.name }}</span
                  >
                  <span class="d-none d-xl-block ms-1 fs-12 user-name-sub-text"
                    >Administratorin</span
                  >
                </span>
              </span>
            </template>
            <h6 class="dropdown-header">
              Welcome {{ currentUser ? currentUser.name : "" }}!
            </h6>

            <router-link class="dropdown-item" to="profile"><i
                class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span class="align-middle"> Profile</span>
            </router-link>

            <div class="dropdown-divider"></div>

            <router-link class="dropdown-item" to="/settings">
              <i
                class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"
              ></i>
              <span class="align-middle"> Einstellungen</span>
            </router-link>

            <b-button class="dropdown-item" @click="logout">
              <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
              <span class="align-middle" data-key="t-logout">Ausfahrt</span>
            </b-button>
          </BDropdown>
        </div>
      </div>
    </div>
  </header>
</template>
