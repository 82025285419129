<script setup>
import { defineProps, ref, watch } from "vue"
import useAbout from "../../use/useAbout"

const props = defineProps({
  aboutModel: {
    required: true,
    type: Object,
  },
})

const aboutInfo = ref(props.aboutModel)

watch(
  () => props.aboutModel,
  (newValue) => {
    aboutInfo.value = newValue
  }
)

const onAboutPageImageChange = (event) => {
  aboutInfo.value.about_page_image = event.target.files[0]
}

const { errors, onSubmit } = useAbout(aboutInfo)
</script>

<template>
  <form @submit.prevent="onSubmit">
    <BRow class="pt-4">

      <BCol lg="6">
        <div class="mb-3">
          <label for="page_title_en" class="form-label">Seitentitel (en)</label>
          <input
            type="text"
            class="form-control"
            id="page_title_en"
            placeholder="Seitentitel (en)"
            v-model="aboutInfo.page_title_en"
          />
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.page_title_en">
              <span>{{ errors.page_title_en[0] }}</span>
            </div>
          </div>
        </div>
      </BCol>
      <BCol lg="6">
        <div class="mb-3">
          <label for="page_title_de" class="form-label">Seitentitel (de)</label>
          <input
            type="text"
            class="form-control"
            id="page_title_de"
            placeholder="Seitentitel (de)"
            v-model="aboutInfo.page_title_de"
          />
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.page_title_de">
              <span>{{ errors.page_title_de[0] }}</span>
            </div>
          </div>
        </div>
      </BCol>

      <BCol lg="6">
        <div class="mb-3">
          <label for="top_text_en" class="form-label">Text oben (en)</label>
          <textarea
            class="form-control"
            id="top_text_en"
            placeholder="Text oben (en)"
            rows="3"
            v-model="aboutInfo.top_text_en"
          ></textarea>
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.top_text_en">
              <span>{{ errors.top_text_en[0] }}</span>
            </div>
          </div>
        </div>
      </BCol>
      <BCol lg="6">
        <div class="mb-3">
          <label for="top_text_de" class="form-label">Text oben (de)</label>
          <textarea
            class="form-control"
            id="top_text_de"
            placeholder="Text oben (de)"
            rows="3"
            v-model="aboutInfo.top_text_de"
          ></textarea>
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.top_text_de">
              <span>{{ errors.top_text_de[0] }}</span>
            </div>
          </div>
        </div>
      </BCol>

      <BCol lg="6">
        <div class="mb-3">
          <label class="form-label" for="about_page_image"
            >Bild der Über uns-Seite</label
          >
          <input
            type="file"
            class="form-control"
            id="about_page_image"
            @change="onAboutPageImageChange"
          />
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.about_page_image">
              <span>{{ errors.about_page_image[0] }}</span>
            </div>
          </div>
        </div>
        <!-- about page image -->
        <b-img
          :src="aboutInfo.about_page_image"
          class="img-thumbnail"
          alt="about page image"
        ></b-img>
      </BCol>

      <BCol lg="12">
        <div class="hstack gap-2 justify-content-end">
          <BButton type="submit" variant="primary"> Aktualisierung </BButton>
        </div>
      </BCol>
    </BRow>
  </form>
</template>
