<script setup>
import { computed, ref, onMounted } from "vue"
import store from "../../state/store"
import useAboutSections from "../../use/useAboutSections"
import AboutSectionModal from "./about-us-section-modal.vue"
import AboutSectionEditModal from "./about-us-section-edit-modal.vue"

const aboutSections = computed(() => store.state.aboutSections)

const { getAboutSections } = useAboutSections()

const DEFAULT_ABOUT_SECTION = {
  id: "",
  about_us_id: "",
  section_title_en: "",
  section_title_de: "",
  section_description_en: "",
  section_description_de: "",
  has_items: "",
  priority: "",
  about_section_image: "",
}

const aboutSectionModel = ref({ ...DEFAULT_ABOUT_SECTION })

const showAboutSectionModal = ref(false)
const showAboutSectionEditModal = ref(false)

const showAddNewModal = () => {
  showAboutSectionModal.value = true
}

const showEditModal = () => {
  showAboutSectionEditModal.value = true
}

onMounted(() => {
  getAboutSections()
})

function editAboutSection(aboutSection) {
  console.log(aboutSection)
  store.dispatch("getAboutSection", aboutSection.id).then(({ data }) => {
    aboutSectionModel.value = data.data

    showEditModal()
  })
}

const onModalClose = () => {
  aboutSectionModel.value = { ...DEFAULT_ABOUT_SECTION }
}
</script>

<template>
  <BRow>
    <BCol lg="12">
      <BCard no-body id="aboutSectionList">
        <BCardHeader class="border-bottom-dashed">
          <BRow class="g-4 align-items-center">
            <BCol sm>
              <div>
                <h5 class="card-title mb-0">Liste der Abschnitte</h5>
              </div>
            </BCol>
            <BCol sm="auto">
              <div class="d-flex flex-wrap align-items-start gap-2">
                <BButton
                  type="button"
                  variant="success"
                  class="add-btn me-1"
                  id="create-btn"
                  @click="showAddNewModal()"
                >
                  <i class="ri-add-line align-bottom me-1"></i> Neuen Abschnitt
                  hinzufügen
                </BButton>
              </div>
            </BCol>
          </BRow>
        </BCardHeader>

        <BCardBody>
          <div>
            <div class="table-responsive table-card mb-1">
              <table class="table align-middle" id="aboutSectionTable">
                <thead class="table-light text-muted">
                  <tr>
                    <th data-sort="about_section_image">Bild</th>
                    <th data-sort="section_title">Titel des Abschnitts</th>
                    <th data-sort="section_description">
                      Abschnittsbeschreibung
                    </th>
                    <th data-sort="priority">Priorität</th>
                    <th data-sort="action">Der Betrieb</th>
                  </tr>
                </thead>

                <tbody
                  class="list"
                  v-if="aboutSections.loading || !aboutSections.data.length"
                >
                  <tr>
                    <td colspan="8">
                      <div class="text-center">
                        <BSpinner
                          v-if="aboutSections.loading"
                          variant="dark"
                          style="width: 3rem; height: 3rem"
                        ></BSpinner>
                        <div v-else>
                          <lottie
                            class="avatar-xl"
                            colors="primary:#121331,secondary:#08a88a"
                            :options="defaultOptions"
                            :height="75"
                            :width="75"
                          />
                          <h5 class="mt-2">Es gibt keinen Abschnitt!</h5>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody class="list" v-else>
                  <tr
                    v-for="(aboutSection, index) of aboutSections.data"
                    :key="index"
                  >
                    <td class="about-section-image">
                      <div class="avatar-sm bg-light rounded p-1">
                        <img
                          :src="aboutSection.about_section_image"
                          :alt="aboutSection.section_title_de"
                          class="img-fluid d-block"
                        />
                      </div>
                    </td>
                    <td class="section-title">
                      {{ aboutSection.section_title_de }}
                    </td>
                    <td class="section-description">
                      {{ aboutSection.section_description_de }}
                    </td>
                    <td class="priority">{{ aboutSection.priority }}</td>
                    <td>
                      <ul class="list-inline hstack gap-2 mb-0">
                        <li
                          class="list-inline-item edit"
                          data-bs-toggle="tooltip"
                          data-bs-trigger="hover"
                          data-bs-placement="top"
                          title="Abschnitt bearbeiten"
                        >
                          <BLink
                            variant="text-primary"
                            class="d-inline-block edit-item-btn"
                            @click="editAboutSection(aboutSection)"
                          >
                            <i class="ri-pencil-fill fs-16"></i>
                          </BLink>
                        </li>

                        <li
                          v-if="aboutSection.has_items"
                          class="list-inline-item"
                          data-bs-toggle="tooltip"
                          data-bs-trigger="hover"
                          data-bs-placement="top"
                          title="Elemente der Abschnittsliste"
                        >
                          <RouterLink
                            :to="{
                              name: 'about.section.list',
                              params: { sectionId: aboutSection.id },
                            }"
                            class="text-primary d-inline-block"
                            target="_blank"
                            ><i class="ri-list-unordered fs-18"></i>
                          </RouterLink>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </BCardBody>
      </BCard>
    </BCol>
  </BRow>

  <AboutSectionModal
    v-model="showAboutSectionModal"
    :aboutSection="aboutSectionModel"
    @close="onModalClose"
  />

  <AboutSectionEditModal
    v-model="showAboutSectionEditModal"
    :aboutSection="aboutSectionModel"
    @close="onModalClose"
  />
</template>
