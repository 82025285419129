<script setup>
import { onUpdated, defineProps, ref, defineEmits } from "vue"
import useSectionListItems from "../../use/useSectionListItems"

const props = defineProps({
  modelValue: Boolean,
  sectionId: Number,
  listItem: {
    required: true,
    type: Object,
  },
})

const listItemData = ref({
  id: props.listItem.id,
  about_us_section_id: props.sectionId,
  item_title_en: props.listItem.item_title_en,
  item_title_de: props.listItem.item_title_de,
  item_description_en: props.listItem.item_description_en,
  item_description_de: props.listItem.item_description_de,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, errors, onSubmit, closeModal } = useSectionListItems(
  props,
  emit,
  listItemData
)

onUpdated(() => {
  listItemData.value = {
    id: props.listItem.id,
    about_us_section_id: props.sectionId,
    item_title_en: props.listItem.item_title_en,
    item_title_de: props.listItem.item_title_de,
    item_description_en: props.listItem.item_description_en,
    item_description_de: props.listItem.item_description_de,
  }
})
</script>

<template>
  <!-- add list item modal  -->
  <BModal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="Füge neuen Gegenstand hinzu"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    size="lg"
    centered
    no-close-on-backdrop
  >
    <BOverlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <BCard>
        <BForm
          id="addform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
        >
          <input type="hidden" v-model="listItemData.about_us_section_id" />

          <div class="row">
            <div class="col-6">
              <div class="mb-3">
                <label for="item_title_en" class="form-label"
                  >Titel des Listenelements (en)</label
                >
                <input
                  type="text"
                  id="item_title_en"
                  class="form-control"
                  placeholder="Titel des Listenelements (en)"
                  v-model="listItemData.item_title_en"
                />
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.item_title_en">
                    <span>{{ errors.item_title_en[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="item_title_de" class="form-label"
                  >Titel des Listenelements (de)</label
                >
                <input
                  type="text"
                  id="item_title_de"
                  class="form-control"
                  placeholder="Titel des Listenelements (de)"
                  v-model="listItemData.item_title_de"
                />
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.item_title_de">
                    <span>{{ errors.item_title_de[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-3">
                <label for="item_description_en" class="form-label"
                  >Beschreibung des Listenelements (en)</label
                >
                <textarea
                  class="form-control"
                  id="item_description_en"
                  placeholder="Beschreibung des Listenelements (en)"
                  rows="2"
                  v-model="listItemData.item_description_en"
                ></textarea>
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.item_description_en"
                  >
                    <span>{{ errors.item_description_en[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label for="item_description_de" class="form-label"
                  >Beschreibung des Listenelements (de)</label
                >
                <textarea
                  class="form-control"
                  id="item_description_de"
                  placeholder="Beschreibung des Listenelements (de)"
                  rows="2"
                  v-model="listItemData.item_description_de"
                ></textarea>
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.item_description_de"
                  >
                    <span>{{ errors.item_description_de[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <BButton
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
              schließen
            </BButton>
            <BButton type="submit" variant="success"> hinzufügen </BButton>
          </div>
        </BForm>
      </BCard>
    </BOverlay>
  </BModal>
</template>
