<script setup>
import { onMounted, ref } from "vue"

import Layout from "@/layouts/main.vue"

import store from "../../state/store"
import GainInfoForm from "./gain-info-form.vue"
import GainListItems from "./gain-list-items.vue"

const DEFAULT_GAIN = {
  id: "",
  gain_section_title_en: "",
  gain_section_title_de: "",
  page_title_en: "",
  page_title_de: "",
  content_title_en: "",
  content_title_de: "",
  top_text_en: "",
  top_text_de: "",
  bottom_text_en: "",
  bottom_text_de: "",
  gain_page_image: "",
  gain_section_image: "",
  small_gain_section_image: "",
}

const gainModel = ref({ ...DEFAULT_GAIN })

const DEFAULT_LIST_ITEM = {
  id: "",
  gain_id: "",
  item_title_en: "",
  item_title_de: "",
  item_description_de: "",
  item_description_en: "",
}

const listItemModel = ref({ ...DEFAULT_LIST_ITEM })

onMounted(async () => {
  const data = await store.dispatch("getGainInfo")
  gainModel.value = {
    ...data.data.data,
  }
})
</script>

<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img :src="gainModel.gain_page_image" class="profile-wid-img" alt="" />
        <div class="overlay-content">
          <h1 class="p-4 text-start text-light">
            {{ gainModel.page_title_de }}
          </h1>
        </div>
      </div>
    </div>

    <BRow>
      <BCol xxl="12">
        <BCard no-body class="mt-xxl-n5">
          <BCardBody class="p-4 pt-2">
            <BTabs nav-class="nav-tabs-custom rounded border-bottom-0">
              <BTab class="nav-item" title="Ihr Gewinn Informationen" active>
                <GainInfoForm :gainModel="gainModel" />
              </BTab>

              <BTab title="Artikel auflisten">
                <GainListItems
                  :gainId="gainModel.id ? gainModel.id : 1"
                  :listItem="listItemModel"
                />
              </BTab>
            </BTabs>
          </BCardBody>
        </BCard>
      </BCol>
    </BRow>
  </Layout>
</template>

<style scoped>
.profile-wid-bg::before {
  opacity: 0.75;
}

.profile-setting-img .overlay-content {
  left: 0;
}
</style>
