export default {
    user: {
        loggedIn: localStorage.getItem('LOGGEDIN') ? true : false,
        isAdmin: localStorage.getItem('USERROLE') === 'Admin' ? true : false,
        token: localStorage.getItem('TOKEN') ? localStorage.getItem('TOKEN') : null,
        data: JSON.parse(localStorage.getItem('USER')) ? JSON.parse(localStorage.getItem('USER')) : {},
    },

    services: {
        loading: false,
        data: [],
    },

    serviceListItems: {
        loading: false,
        data: [],
    },

    gainListItems: {
        loading: false,
        data: [],
    },

    aboutSections: {
        loading: false,
        data: [],
    },

    team: {
        loading: false,
        data: [],
    },

    sectionListItems: {
        loading: false,
        data: [],
    },
}

