import { ref, computed } from "vue";
import { useToast } from "vue-toastification"

import store from "../state/store"
import useDelete from './useDelete';


export default function useGainListItems({ props, emit, listItem }) {

    // Get toast interface
    const toast = useToast()

    const loading = ref(false)
    const errors = ref({})

    const show = computed({
        get: () => props.modelValue,
        set: (value) => emit("update:modelValue", value),
    })


    const getGainListItems = async () => {
        try {
            await store.dispatch("getGainListItems", listItem.value.gain_id)
        } catch (error) {
            console.error(error)
            return null
        }
    }

    const onSubmit = () => {
        loading.value = true
        if (listItem.value.id) {
            store.dispatch('updateGainListItem', listItem.value)
                .then(response => {
                    loading.value = false;
                    if (response.status === 200) {
                        toast.success("Erfolgreich aktualisiert!", {
                            timeout: 3000,
                        })
                        store.dispatch("getGainListItems", response.data.data.gain_id)
                        clearForm(listItem)
                    }
                })
                .catch((err) => {
                    loading.value = false
                    errors.value = err.response.data.errors
                })

        } else {

            store
                .dispatch("createGainListItem", listItem.value)
                .then((response) => {
                    loading.value = false
                    if (response.status === 201) {
                        toast.success("Erfolgreich hinzugefügt!", {
                            timeout: 3000,
                        })

                        store.dispatch("getGainListItems", response.data.data.gain_id)
                        clearForm(listItem)
                    }
                })
                .catch((err) => {
                    loading.value = false
                    errors.value = err.response.data.errors
                })
        }

    }

    const clearForm = (listItem) => {
        errors.value = {}
        listItem.value.id = ''
        listItem.value.item_title_en = ''
        listItem.value.item_title_de = ''
        listItem.value.item_description_en = ''
        listItem.value.item_description_de = ''
    }

    const deleteGainListItem = (listItem) => {
        const { confirmDelete } = useDelete(
            () => store.dispatch("deleteGainListItem", listItem),
            'Erfolgreich entfernt!',
            getGainListItems
        );

        confirmDelete();
    };

    return {
        show,
        loading,
        errors,
        getGainListItems,
        onSubmit,
        clearForm,
        deleteGainListItem,
    }
}