<script setup>
import useAboutSections from "../../use/useAboutSections"
import { onUpdated, defineProps, ref, defineEmits } from "vue"

const props = defineProps({
  modelValue: Boolean,
  aboutSection: {
    required: true,
    type: Object,
  },
})

const aboutSectionData = ref({
  id: props.aboutSection.id,
  about_us_id: props.aboutSection.about_us_id,
  section_title_en: props.aboutSection.section_title_en,
  section_title_de: props.aboutSection.section_title_de,
  section_description_en: props.aboutSection.section_description_en,
  section_description_de: props.aboutSection.section_description_de,
  has_items: props.aboutSection.has_items,
  priority: props.aboutSection.priority,
  about_section_image: props.aboutSection.about_section_image,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, errors, onSubmit, closeModal } = useAboutSections(
  props,
  emit,
  aboutSectionData
)

onUpdated(() => {
  aboutSectionData.value = {
    id: props.aboutSection.id,
    about_us_id: props.aboutSection.about_us_id,
    section_title_en: props.aboutSection.section_title_en,
    section_title_de: props.aboutSection.section_title_de,
    section_description_en: props.aboutSection.section_description_en,
    section_description_de: props.aboutSection.section_description_de,
    has_items: props.aboutSection.has_items,
    priority: props.aboutSection.priority,
    about_section_image: props.aboutSection.about_section_image,
  }
})

const onAboutSectionImageChange = (event) => {
  aboutSectionData.value.about_section_image = event.target.files[0]
}

</script>

<template>
  <!-- add about us section modal  -->
  <BModal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="Abschnitt hinzufügen"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    size="lg"
    centered
    no-close-on-backdrop
  >
    <BOverlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <BCard>
        <BForm
          id="addform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
          enctype="multipart/form-data"
        >
          <div class="row">
            <div class="col-12">
              <div class="mb-3">
                <label for="section_title_en" class="form-label"
                  >Abschnittstitel (en)</label
                >
                <input
                  type="text"
                  id="section_title_en"
                  class="form-control"
                  placeholder="Abschnittstitel (en)"
                  v-model="aboutSectionData.section_title_en"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.section_title_en"
                  >
                    <span>{{ errors.section_title_en[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label for="section_title_de" class="form-label"
                  >Abschnittstitel (de)</label
                >
                <input
                  type="text"
                  id="section_title_de"
                  class="form-control"
                  placeholder="Abschnittstitel (de)"
                  v-model="aboutSectionData.section_title_de"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.section_title_de"
                  >
                    <span>{{ errors.section_title_de[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-3">
                <label for="section_description_en" class="form-label"
                  >Abschnittsbeschreibung (en)</label
                >
                <textarea
                  class="form-control"
                  id="section_description_en"
                  placeholder="Abschnittsbeschreibung (en)"
                  rows="2"
                  v-model="aboutSectionData.section_description_en"
                ></textarea>
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.section_description_en">
                    <span>{{ errors.section_description_en[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label for="section_description_de" class="form-label"
                  >Abschnittsbeschreibung (de)</label
                >
                <textarea
                  class="form-control"
                  id="section_description_de"
                  placeholder="Abschnittsbeschreibung (de)"
                  rows="2"
                  v-model="aboutSectionData.section_description_de"
                ></textarea>
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.section_description_de">
                    <span>{{ errors.section_description_de[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="mb-3">
                <label for="priority" class="form-label">Priorität</label>
                <input
                  type="number"
                  id="priority"
                  class="form-control"
                  placeholder="Priorität"
                  v-model="aboutSectionData.priority"
                />
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.priority">
                    <span>{{ errors.priority[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="mb-3">
                <label for="has_items" class="form-label"
                  >verfügt über Artikel</label
                >
                <select class="form-select" id="has_items" v-model="aboutSectionData.has_items">
                  <option value="0">NEIN</option>
                  <option value="1">Ja</option>
                  </select>
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.has_items"
                  >
                    <span>{{ errors.has_items[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-3">
                <label for="about_section_image" class="form-label"
                  >Bild des About-Bereichs</label
                >
                <input
                  class="form-control"
                  type="file"
                  id="about_section_image"
                  @change="onAboutSectionImageChange"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.about_section_image"
                  >
                    <span>{{ errors.about_section_image[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <BButton
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
              schließen
            </BButton>
            <BButton type="submit" variant="success">
              Abschnitt hinzufügen
            </BButton>
          </div>
        </BForm>
      </BCard>
    </BOverlay>
  </BModal>
</template>
