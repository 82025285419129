<script setup>
import { defineProps, computed, onMounted, ref, defineEmits } from "vue"

import Lottie from "@/components/widgets/lottie.vue"
import animationData from "@/components/widgets/msoeawqm.json"

import store from "../../state/store"
import useSectionListItems from "../../use/useSectionListItems"

const props = defineProps({
  sectionId: Number,
})


const sectionListItems = computed(() => store.state.sectionListItems)


const { getSectionListItems, deleteSectionListItem } = useSectionListItems({
  sectionId: props.sectionId,
})

const emit = defineEmits(["clickEdit"])

onMounted(() => {
  getSectionListItems()
})

const defaultOptions = ref({
  animationData: animationData,
})

const editSectionListItem = (listItem) => {
  emit("clickEdit", listItem)
}
</script>

<template>
  <BCardBody>
    <div>
      <div class="table-responsive table-card mb-1">
        <table class="table align-middle" id="sectionListItemTable">
          <thead class="table-light text-muted">
            <tr>
              <th class="sort" data-sort="section_title">Abschnittstitel</th>
              <th class="sort" data-sort="item_title">
                Titel des Listenelements
              </th>
              <th class="sort" data-sort="item_description">
                Beschreibung des Listenelements
              </th>
              <th class="sort" data-sort="action">Der Betrieb</th>
            </tr>
          </thead>

          <tbody
            class="list"
            v-if="sectionListItems.loading || !sectionListItems.data.length"
          >
            <tr>
              <td colspan="8">
                <div class="text-center">
                  <BSpinner
                    v-if="sectionListItems.loading"
                    variant="dark"
                    style="width: 3rem; height: 3rem"
                  ></BSpinner>
                  <div v-else>
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">Es gibt keinen item!</h5>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody class="list" v-else>
            <tr v-for="(listItem, index) of sectionListItems.data" :key="index">
              <td class="section-title">
                {{ listItem.section_title_de }}
              </td>
              <td class="item-title">{{ listItem.item_title_de }}</td>
              <td class="item-description">
                {{ listItem.item_description_de }}
              </td>
              <td>
                <ul class="list-inline hstack gap-2 mb-0">
                  <li
                    class="list-inline-item edit"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="Bearbeiten"
                  >
                    <BLink
                      variant="text-primary"
                      class="d-inline-block edit-item-btn"
                      @click="editSectionListItem(listItem)"
                    >
                      <i class="ri-pencil-fill fs-16"></i>
                    </BLink>
                  </li>
                  <li
                    class="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="löschen"
                  >
                    <BLink
                      class="text-danger d-inline-block remove-item-btn"
                      @click="deleteSectionListItem(listItem)"
                    >
                      <i class="ri-delete-bin-5-fill fs-16"></i>
                    </BLink>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </BCardBody>
</template>
