<script setup>
import { ref, onMounted } from "vue"
import { useToast } from "vue-toastification"
import Layout from "@/layouts/main.vue"

import store from "../../state/store"

// Get toast interface
const toast = useToast()

const homePageInfo = ref([])
const errors = ref({})
const loading = ref(false)

onMounted(async () => {
  const data = await store.dispatch("getHomepageInfo")
  homePageInfo.value = {
    ...data.data.data,
  }
})

const onHomepageImageChange = (event) => {
  homePageInfo.value.homepage_image = event.target.files[0]
}

const onExperienceBoxImageChange = (event) => {
  homePageInfo.value.experience_box_image = event.target.files[0]
}

const onSubmit = () => {
  loading.value = true
  store
    .dispatch("updateHomepageInfo", homePageInfo.value)
    .then((response) => {
      loading.value = false
      if (response.status === 200) {
        toast.success(
          "Die Informationen zur Startseite wurden erfolgreich aktualisiert.",
          {
            timeout: 3000,
          }
        )
      }
    })
    .catch((err) => {
      loading.value = false
      errors.value = err.response.data.errors
    })
}
</script>

<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img
          :src="homePageInfo.homepage_image"
          class="profile-wid-img"
          alt=""
        />
        <div class="overlay-content">
          <h1 class="p-4 text-start text-light">
            {{ homePageInfo.home_title_de }}
          </h1>
        </div>
      </div>
    </div>

    <BRow>
      <BCol xxl="12">
        <BCard no-body class="mt-xxl-n5">
          <BCardBody class="p-4 pt-2">
            <BTabs nav-class="nav-tabs-custom rounded border-bottom-0">
              <BTab class="nav-item" title="Homepage Informationen" active>
                <form @submit.prevent="onSubmit">
                  <BRow class="pt-4">
                    <BCol lg="6">
                      <div class="mb-3">
                        <label for="homepagetitleen" class="form-label"
                          >Title (en)</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="homepagetitleen"
                          placeholder="Title (en)"
                          v-model="homePageInfo.home_title_en"
                        />
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.home_title_en"
                          >
                            <span>{{ errors.home_title_en[0] }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>
                    <BCol lg="6">
                      <div class="mb-3">
                        <label for="homepagetitlede" class="form-label"
                          >Title (de)</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="homepagetitlede"
                          placeholder="Title (de)"
                          v-model="homePageInfo.home_title_de"
                        />
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.home_title_de"
                          >
                            <span>{{ errors.home_title_de[0] }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>
                    <BCol lg="6">
                      <div class="mb-3">
                        <label for="experienceboxtitleen" class="form-label"
                          >Erfahrung Titel (en)</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="experienceboxtitleen"
                          placeholder="Erfahrung Titel (en)"
                          v-model="homePageInfo.experience_box_title_en"
                        />
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.experience_box_title_en"
                          >
                            <span>{{ errors.experience_box_title_en[0] }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>
                    <BCol lg="6">
                      <div class="mb-3">
                        <label for="experienceboxtitlede" class="form-label"
                          >Erfahrung Titel (de)</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="experienceboxtitlede"
                          placeholder="Erfahrung Titel (de)"
                          v-model="homePageInfo.experience_box_title_de"
                        />
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.experience_box_title_de"
                          >
                            <span>{{ errors.experience_box_title_de[0] }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>

                    <BCol lg="6">
                      <div class="mb-3">
                        <label for="experienceboxshorttexten" class="form-label"
                          >Erfahrung Kurzer Text (en)</label
                        >
                        <textarea
                          class="form-control"
                          id="experienceboxshorttexten"
                          placeholder="Erfahrung Kurzer Text (en)"
                          rows="2"
                          v-model="homePageInfo.experience_box_short_text_en"
                        ></textarea>
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.experience_box_short_text_en"
                          >
                            <span>{{
                              errors.experience_box_short_text_en[0]
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>
                    <BCol lg="6">
                      <div class="mb-3">
                        <label for="experienceboxshorttextde" class="form-label"
                          >Erfahrung Kurzer Text (de)</label
                        >
                        <textarea
                          class="form-control"
                          id="experienceboxshorttextde"
                          placeholder="Erfahrung Kurzer Text (de)"
                          rows="2"
                          v-model="homePageInfo.experience_box_short_text_de"
                        ></textarea>
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.experience_box_short_text_de"
                          >
                            <span>{{
                              errors.experience_box_short_text_de[0]
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>

                    <BCol lg="6">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlTextarea"
                          class="form-label"
                          >Erfahrung langer Text (en)</label
                        >
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea"
                          placeholder="Erfahrung langer Text (en)"
                          rows="3"
                          v-model="homePageInfo.experience_box_long_text_en"
                        ></textarea>
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.experience_box_long_text_en"
                          >
                            <span>{{
                              errors.experience_box_long_text_en[0]
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>
                    <BCol lg="6">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlTextarea"
                          class="form-label"
                          >Erfahrung langer Text (de)</label
                        >
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea"
                          placeholder="Erfahrung langer Text (de)"
                          rows="3"
                          v-model="homePageInfo.experience_box_long_text_de"
                        ></textarea>
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.experience_box_long_text_de"
                          >
                            <span>{{
                              errors.experience_box_long_text_de[0]
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>

                    <BCol lg="6">
                      <div class="mb-3">
                        <label class="form-label" for="homepageimage"
                          >Bild der Homepage</label
                        >
                        <input
                          type="file"
                          class="form-control"
                          id="homepageimage"
                          @change="onHomepageImageChange"
                        />
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.homepage_image"
                          >
                            <span>{{ errors.homepage_image[0] }}</span>
                          </div>
                        </div>
                      </div>
                      <!-- homepage image -->
                      <b-img
                        :src="homePageInfo.homepage_image"
                        class="img-thumbnail"
                        alt="homepage image"
                      ></b-img>
                    </BCol>
                    <BCol lg="6">
                      <div class="mb-3">
                        <label for="experienceboximage" class="form-label"
                          >Bild der Erfahrung</label
                        >
                        <input
                          type="file"
                          class="form-control"
                          id="experienceboximage"
                          @change="onExperienceBoxImageChange"
                        />
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.experience_box_image"
                          >
                            <span>{{ errors.experience_box_image[0] }}</span>
                          </div>
                        </div>
                      </div>
                      <!-- experience box image -->
                      <b-img
                        :src="homePageInfo.experience_box_image"
                        class="img-thumbnail"
                        alt="experience box image"
                      >
                      </b-img>
                    </BCol>

                    <BCol lg="12">
                      <div class="hstack gap-2 justify-content-end">
                        <BButton type="submit" variant="primary">
                          Aktualisierung
                        </BButton>
                      </div>
                    </BCol>
                  </BRow>
                </form>
              </BTab>

              <BTab title="Andere Abschnitte">
                <div class="pb-2 pt-4">
                  <div class="d-flex flex-column flex-md-row">
                    <div class="mb-3 mb-md-0 me-md-4">
                      <BLink href="/services" class="btn btn-sm btn-primary"
                        >Dienstleistungen
                      </BLink>
                    </div>

                    <div>
                      <BLink href="/gain" class="btn btn-sm btn-primary"
                        >Ihr Gewinn</BLink
                      >
                    </div>
                  </div>
                </div>
              </BTab>
            </BTabs>
          </BCardBody>
        </BCard>
      </BCol>
    </BRow>
  </Layout>
</template>

<style scoped>
.profile-wid-bg::before {
  opacity: 0.75;
}

.profile-setting-img .overlay-content {
  left: 0;
}
</style>
