<script setup>
import { defineProps, ref, watch } from "vue"
import useGain from "../../use/useGain"

const props = defineProps({
  gainModel: {
    required: true,
    type: Object,
  },
})

const gainInfo = ref(props.gainModel)

watch(
  () => props.gainModel,
  (newValue) => {
    gainInfo.value = newValue
  }
)

const onGainPageImageChange = (event) => {
  gainInfo.value.gain_page_image = event.target.files[0]
}
const onGainSectionImageChange = (event) => {
  gainInfo.value.gain_section_image = event.target.files[0]
}
const onSmallGainSectionImageChange = (event) => {
  gainInfo.value.small_gain_section_image = event.target.files[0]
}

const { errors, onSubmit } = useGain(gainInfo)
</script>

<template>
  <form @submit.prevent="onSubmit">
    <BRow class="pt-4">
      <BCol lg="6">
        <div class="mb-3">
          <label for="gain_section_title_en" class="form-label"
            >Abschnittstitel (en)</label
          >
          <input
            type="text"
            class="form-control"
            id="gain_section_title_en"
            placeholder="Abschnittstitel (en)"
            v-model="gainInfo.gain_section_title_en"
          />
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.gain_section_title_en">
              <span>{{ errors.gain_section_title_en[0] }}</span>
            </div>
          </div>
        </div>
      </BCol>
      <BCol lg="6">
        <div class="mb-3">
          <label for="gain_section_title_de" class="form-label"
            >Abschnittstitel (de)</label
          >
          <input
            type="text"
            class="form-control"
            id="gain_section_title_de"
            placeholder="Abschnittstitel (de)"
            v-model="gainInfo.gain_section_title_de"
          />
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.gain_section_title_de">
              <span>{{ errors.gain_section_title_de[0] }}</span>
            </div>
          </div>
        </div>
      </BCol>

      <BCol lg="6">
        <div class="mb-3">
          <label for="page_title_en" class="form-label">Seitentitel (en)</label>
          <input
            type="text"
            class="form-control"
            id="page_title_en"
            placeholder="Seitentitel (en)"
            v-model="gainInfo.page_title_en"
          />
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.page_title_en">
              <span>{{ errors.page_title_en[0] }}</span>
            </div>
          </div>
        </div>
      </BCol>
      <BCol lg="6">
        <div class="mb-3">
          <label for="page_title_de" class="form-label">Seitentitel (de)</label>
          <input
            type="text"
            class="form-control"
            id="page_title_de"
            placeholder="Seitentitel (de)"
            v-model="gainInfo.page_title_de"
          />
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.page_title_de">
              <span>{{ errors.page_title_de[0] }}</span>
            </div>
          </div>
        </div>
      </BCol>

      <BCol lg="6">
        <div class="mb-3">
          <label for="content_title_en" class="form-label"
            >Inhaltstitel (en)</label
          >
          <input
            type="text"
            class="form-control"
            id="content_title_en"
            placeholder="Inhaltstitel (en)"
            v-model="gainInfo.content_title_en"
          />
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.content_title_en">
              <span>{{ errors.content_title_en[0] }}</span>
            </div>
          </div>
        </div>
      </BCol>
      <BCol lg="6">
        <div class="mb-3">
          <label for="content_title_de" class="form-label"
            >Inhaltstitel (de)</label
          >
          <input
            type="text"
            class="form-control"
            id="content_title_de"
            placeholder="Inhaltstitel (de)"
            v-model="gainInfo.content_title_de"
          />
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.content_title_de">
              <span>{{ errors.content_title_de[0] }}</span>
            </div>
          </div>
        </div>
      </BCol>

      <BCol lg="6">
        <div class="mb-3">
          <label for="top_text_en" class="form-label">Text oben (en)</label>
          <textarea
            class="form-control"
            id="top_text_en"
            placeholder="Text oben (en)"
            rows="2"
            v-model="gainInfo.top_text_en"
          ></textarea>
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.top_text_en">
              <span>{{ errors.top_text_en[0] }}</span>
            </div>
          </div>
        </div>
      </BCol>
      <BCol lg="6">
        <div class="mb-3">
          <label for="top_text_de" class="form-label">Text oben (de)</label>
          <textarea
            class="form-control"
            id="top_text_de"
            placeholder="Text oben (de)"
            rows="2"
            v-model="gainInfo.top_text_de"
          ></textarea>
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.top_text_de">
              <span>{{ errors.top_text_de[0] }}</span>
            </div>
          </div>
        </div>
      </BCol>

      <BCol lg="6">
        <div class="mb-3">
          <label for="bottom_text_en" class="form-label"
            >Unterer Text (en)</label
          >
          <textarea
            class="form-control"
            id="bottom_text_en"
            placeholder="Unterer Text (en)"
            rows="3"
            v-model="gainInfo.bottom_text_en"
          ></textarea>
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.bottom_text_en">
              <span>{{ errors.bottom_text_en[0] }}</span>
            </div>
          </div>
        </div>
      </BCol>
      <BCol lg="6">
        <div class="mb-3">
          <label for="bottom_text_de" class="form-label"
            >Unterer Text (de)</label
          >
          <textarea
            class="form-control"
            id="bottom_text_de"
            placeholder="Unterer Text (de)"
            rows="3"
            v-model="gainInfo.bottom_text_de"
          ></textarea>
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.bottom_text_de">
              <span>{{ errors.bottom_text_de[0] }}</span>
            </div>
          </div>
        </div>
      </BCol>

      <BCol lg="4">
        <div class="mb-3">
          <label class="form-label" for="gain_page_image"
            >Bild der Gain-Seite</label
          >
          <input
            type="file"
            class="form-control"
            id="gain_page_image"
            @change="onGainPageImageChange"
          />
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.gain_page_image">
              <span>{{ errors.gain_page_image[0] }}</span>
            </div>
          </div>
        </div>
        <!-- gain page image -->
        <b-img
          :src="gainInfo.gain_page_image"
          class="img-thumbnail"
          alt="gain page image"
        ></b-img>
      </BCol>
      <BCol lg="4">
        <div class="mb-3">
          <label for="gain_section_image" class="form-label"
            >Bild des Gain-Bereichs</label
          >
          <input
            type="file"
            class="form-control"
            id="gain_section_image"
            @change="onGainSectionImageChange"
          />
          <div v-if="errors">
            <div class="invalid-feedback" v-if="errors.gain_section_image">
              <span>{{ errors.gain_section_image[0] }}</span>
            </div>
          </div>
        </div>
        <!-- gain section image -->
        <b-img
          :src="gainInfo.gain_section_image"
          class="img-thumbnail"
          alt="gain section image"
        >
        </b-img>
      </BCol>
      <BCol lg="4">
        <div class="mb-3">
          <label for="small_gain_section_image" class="form-label"
            >Bild des Abschnitts mit kleiner Verstärkung</label
          >
          <input
            type="file"
            class="form-control"
            id="small_gain_section_image"
            @change="onSmallGainSectionImageChange"
          />
          <div v-if="errors">
            <div
              class="invalid-feedback"
              v-if="errors.small_gain_section_image"
            >
              <span>{{ errors.small_gain_section_image[0] }}</span>
            </div>
          </div>
        </div>
        <!-- gain section image -->
        <b-img
          :src="gainInfo.small_gain_section_image"
          class="img-thumbnail"
          alt="gain section image"
        >
        </b-img>
      </BCol>

      <BCol lg="12">
        <div class="hstack gap-2 justify-content-end">
          <BButton type="submit" variant="primary"> Aktualisierung </BButton>
        </div>
      </BCol>
    </BRow>
  </form>
</template>
