<script setup>
import { ref } from "vue"
import Layout from "@/layouts/main.vue"
import PageHeader from "@/components/page-header"

import store from "../../state/store"
import ServicesTable from "./services-table.vue"
import ServiceModal from "./service-modal.vue"
import ServiceEditModal from "./service-edit-modal.vue"

const title = "Dienstleistungen"
const pageTitle = "Armaturenbrett"

const DEFAULT_SERVICE = {
  id: "",
  service_column_title_en: "",
  service_column_title_de: "",
  page_title_en: "",
  page_title_de: "",
  top_text_en: "",
  top_text_de: "",
  bottom_text_en: "",
  bottom_text_de: "",
  priority: "",
  service_column_image: "",
  service_page_image: "",
}

const serviceModel = ref({ ...DEFAULT_SERVICE })
const showServiceModal = ref(false)
const showServiceEditModal = ref(false)

const showAddNewModal = () => {
  showServiceModal.value = true
}

const showEditModal = () => {
  showServiceEditModal.value = true
}

function editService(service) {
  store.dispatch("getService", service.id).then(({ data }) => {
    serviceModel.value = data.data
    showEditModal()
  })
}

const onModalClose = () => {
  serviceModel.value = { ...DEFAULT_SERVICE }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />

    <BRow>
      <BCol lg="12">
        <BCard no-body id="serviceList">
          <BCardHeader class="border-bottom-dashed">
            <BRow class="g-4 align-items-center">
              <BCol sm>
                <div>
                  <h5 class="card-title mb-0">Liste der Dienstleistungen</h5>
                </div>
              </BCol>
              <BCol sm="auto">
                <div class="d-flex flex-wrap align-items-start gap-2">
                  <BButton
                    type="button"
                    variant="success"
                    class="add-btn me-1"
                    id="create-btn"
                    @click="showAddNewModal()"
                  >
                    <i class="ri-add-line align-bottom me-1"></i> Neuen Dienst
                    hinzufügen
                  </BButton>
                </div>
              </BCol>
            </BRow>
          </BCardHeader>

          <!-- services table  -->

          <ServicesTable @clickEdit="editService" />

          <!-- add and edit service modal  -->

          <ServiceModal
            v-model="showServiceModal"
            :service="serviceModel"
            @close="onModalClose"
          />

          <ServiceEditModal
            v-model="showServiceEditModal"
            :service="serviceModel"
            @close="onModalClose"
          />
        </BCard>
      </BCol>
    </BRow>
  </Layout>
</template>
