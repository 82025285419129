<script setup>
import { ref, onMounted } from "vue"
import { useToast } from "vue-toastification"
import Layout from "@/layouts/main.vue"

import CKEditor from "@ckeditor/ckeditor5-vue"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import store from "../../state/store"

// Get toast interface
const toast = useToast()

const settings = ref([])
const errors = ref({})
const loading = ref(false)

onMounted(async () => {
  const data = await store.dispatch("getSettings")
  settings.value = {
    ...data.data.data,
  }
})

/**
 *! start editor
 */

const editorConfig = {
  toolbar: [
    "undo",
    "redo",
    "|",
    "heading",
    "|",
    "bold",
    "italic",
    "-", // break point
    "|",
    "alignment",
    "link",
    "|",
    "bulletedList",
    "numberedList",
  ],
}

const editor = ClassicEditor
const ckeditor = CKEditor.component

/**
 *! end editor
 */

const onPrivacyImageChange = (event) => {
  settings.value.privacy_image = event.target.files[0]
}

const onImprintImageChange = (event) => {
  settings.value.imprint_image = event.target.files[0]
}

const onSubmit = () => {
  loading.value = true
  store
    .dispatch("updateSettings", settings.value)
    .then((response) => {
      loading.value = false
      if (response.status === 200) {
        toast.success("Die Einstellungen wurden erfolgreich aktualisiert.", {
          timeout: 3000,
        })
      }
    })
    .catch((err) => {
      loading.value = false
      errors.value = err.response.data.errors
    })
}
</script>

<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img :src="settings.imprint_image" class="profile-wid-img" alt="" />
        <div class="overlay-content">
          <h1 class="p-4 text-start text-light">
            Einstellungen und Datenschutz
          </h1>
        </div>
      </div>
    </div>

    <BRow>
      <BCol xxl="12">
        <BCard no-body class="mt-xxl-n5">
          <BCardBody class="p-4 pt-2">
            <BTabs nav-class="nav-tabs-custom rounded border-bottom-0">
              <BTab
                class="nav-item"
                title="Einstellungen und Datenschutz"
                active
              >
                <form @submit.prevent="onSubmit">
                  <BRow class="pt-4">
                    <BCol lg="6">
                      <div class="mb-3">
                        <label for="phone" class="form-label"
                          >Telefonnummer</label
                        >
                        <input
                          type="tel"
                          class="form-control"
                          id="phone"
                          placeholder="Telefonnummer"
                          v-model="settings.phone"
                        />
                        <div v-if="errors">
                          <div class="invalid-feedback" v-if="errors.phone">
                            <span>{{ errors.phone[0] }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>
                    <BCol lg="6">
                      <div class="mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input
                          type="text"
                          class="form-control"
                          id="email"
                          placeholder="Email"
                          v-model="settings.email"
                        />
                        <div v-if="errors">
                          <div class="invalid-feedback" v-if="errors.email">
                            <span>{{ errors.email[0] }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>

                    <BCol lg="12">
                      <div class="mb-3">
                        <label for="address" class="form-label">Adresse</label>
                        <textarea
                          class="form-control"
                          id="address"
                          placeholder="Adresse"
                          rows="2"
                          v-model="settings.address"
                        ></textarea>
                        <div v-if="errors">
                          <div class="invalid-feedback" v-if="errors.address">
                            <span>{{ errors.address[0] }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>

                    <BCol lg="6">
                      <div class="mb-3">
                        <label for="privacy_text_en" class="form-label"
                          >Datenschutz (en)</label
                        >

                        <ckeditor
                          id="privacy_text_en"
                          v-model="settings.privacy_text_en"
                          :editor="editor"
                          :config="editorConfig"
                        ></ckeditor>

                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.privacy_text_en"
                          >
                            <span>{{ errors.privacy_text_en[0] }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>
                    <BCol lg="6">
                      <div class="mb-3">
                        <label for="privacy_text_de" class="form-label"
                          >Datenschutz (de)</label
                        >
                        <ckeditor
                          id="privacy_text_de"
                          v-model="settings.privacy_text_de"
                          :editor="editor"
                          :config="editorConfig"
                        ></ckeditor>
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.privacy_text_de"
                          >
                            <span>{{ errors.privacy_text_de[0] }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>

                    <BCol lg="6">
                      <div class="mb-3">
                        <label for="imprint_text_en" class="form-label"
                          >Impressum (en)</label
                        >
                        <ckeditor
                          id="imprint_text_en"
                          v-model="settings.imprint_text_en"
                          :editor="editor"
                          :config="editorConfig"
                        ></ckeditor>
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.imprint_text_en"
                          >
                            <span>{{ errors.imprint_text_en[0] }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>
                    <BCol lg="6">
                      <div class="mb-3">
                        <label for="imprint_text_de" class="form-label"
                          >Impressum (de)</label
                        >
                        <ckeditor
                          id="imprint_text_de"
                          v-model="settings.imprint_text_de"
                          :editor="editor"
                          :config="editorConfig"
                        ></ckeditor>
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.imprint_text_de"
                          >
                            <span>{{ errors.imprint_text_de[0] }}</span>
                          </div>
                        </div>
                      </div>
                    </BCol>

                    <BCol lg="6">
                      <div class="mb-3">
                        <label class="form-label" for="imprint_image"
                          >Bild der Impressum</label
                        >
                        <input
                          type="file"
                          class="form-control"
                          id="imprint_image"
                          @change="onImprintImageChange"
                        />
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.imprint_image"
                          >
                            <span>{{ errors.imprint_image[0] }}</span>
                          </div>
                        </div>
                      </div>
                      <!-- imprint image -->
                      <b-img
                        :src="settings.imprint_image"
                        class="img-thumbnail"
                        alt="imprint image"
                      ></b-img>
                    </BCol>
                    <BCol lg="6">
                      <div class="mb-3">
                        <label for="privacy_image" class="form-label"
                          >Bild der Datenschutz</label
                        >
                        <input
                          type="file"
                          class="form-control"
                          id="privacy_image"
                          @change="onPrivacyImageChange"
                        />
                        <div v-if="errors">
                          <div
                            class="invalid-feedback"
                            v-if="errors.privacy_image"
                          >
                            <span>{{ errors.privacy_image[0] }}</span>
                          </div>
                        </div>
                      </div>
                      <!-- privacy image -->
                      <b-img
                        :src="settings.privacy_image"
                        class="img-thumbnail"
                        alt="privacy image"
                      >
                      </b-img>
                    </BCol>

                    <BCol lg="12">
                      <div class="hstack gap-2 justify-content-end">
                        <BButton type="submit" variant="primary">
                          Aktualisierung
                        </BButton>
                      </div>
                    </BCol>
                  </BRow>
                </form>
              </BTab>
            </BTabs>
          </BCardBody>
        </BCard>
      </BCol>
    </BRow>
  </Layout>
</template>

<style scoped>
.profile-wid-bg::before {
  opacity: 0.75;
}

.profile-setting-img .overlay-content {
  left: 0;
}
</style>
