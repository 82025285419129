import axiosClient from "../../axios";

/**
 * Homepage functions
 */
export async function getHomepageInfo() {
    return await axiosClient.get('/admin/home/info')

}

export function updateHomepageInfo(_, homepageInfo) {

    const form = new FormData();

    form.append('home_title_en', homepageInfo.home_title_en || '');
    form.append('home_title_de', homepageInfo.home_title_de || '');

    form.append('experience_box_title_en', homepageInfo.experience_box_title_en || '');
    form.append('experience_box_title_de', homepageInfo.experience_box_title_de || '');

    form.append('experience_box_short_text_en', homepageInfo.experience_box_short_text_en || '');
    form.append('experience_box_short_text_de', homepageInfo.experience_box_short_text_de || '');

    form.append('experience_box_long_text_en', homepageInfo.experience_box_long_text_en || '');
    form.append('experience_box_long_text_de', homepageInfo.experience_box_long_text_de || '');



    if (homepageInfo.homepage_image instanceof File) {
        form.append('homepage_image', homepageInfo.homepage_image);
    } else {
        form.append('homepage_image', '');
    }

    if (homepageInfo.experience_box_image instanceof File) {
        form.append('experience_box_image', homepageInfo.experience_box_image);
    } else {
        form.append('experience_box_image', '');
    }


    form.append('_method', 'PUT');
    homepageInfo = form;

    return axiosClient.post(`/admin/home/update`, homepageInfo)
}

/**
 * Services functions
 */
export async function createService(_, service) {
    const form = new FormData();
    form.append('service_column_title_en', service.service_column_title_en || '');
    form.append('service_column_title_de', service.service_column_title_de || '');
    form.append('page_title_en', service.page_title_en || '');
    form.append('page_title_de', service.page_title_de || '');
    form.append('top_text_en', service.top_text_en || '');
    form.append('top_text_de', service.top_text_de || '');
    form.append('bottom_text_en', service.bottom_text_en || '');
    form.append('bottom_text_de', service.bottom_text_de || '');
    form.append('priority', service.priority || '');


    if (service.service_column_image instanceof File) {
        form.append('service_column_image', service.service_column_image);
    } else {
        form.append('service_column_image', '');
    }

    if (service.service_page_image instanceof File) {
        form.append('service_page_image', service.service_page_image);
    } else {
        form.append('service_page_image', '');
    }

    service = form;
    return await axiosClient.post('/admin/services', service)
}

export async function getServices({ commit }) {
    commit('setServices', [true])

    return await axiosClient.get('/admin/services')
        .then((response) => {
            commit('setServices', [false, response.data])
        })
        .catch(() => {
            commit('setServices', [false])
        })
}

export async function getService(_, id) {
    return await axiosClient.get(`/admin/services/${id}`)
}

export function updateService(_, service) {

    const id = service.id
    const form = new FormData();
    form.append('id', service.id);
    form.append('service_column_title_en', service.service_column_title_en || '');
    form.append('service_column_title_de', service.service_column_title_de || '');
    form.append('page_title_en', service.page_title_en || '');
    form.append('page_title_de', service.page_title_de || '');
    form.append('top_text_en', service.top_text_en || '');
    form.append('top_text_de', service.top_text_de || '');
    form.append('bottom_text_en', service.bottom_text_en || '');
    form.append('bottom_text_de', service.bottom_text_de || '');
    form.append('priority', service.priority || '');


    if (service.service_column_image instanceof File) {
        form.append('service_column_image', service.service_column_image);
    } else {
        form.append('service_column_image', '');
    }

    if (service.service_page_image instanceof File) {
        form.append('service_page_image', service.service_page_image);
    } else {
        form.append('service_page_image', '');
    }

    form.append('_method', 'PUT');
    service = form;

    return axiosClient.post(`/admin/services/${id}`, service)

}


/**
 * Service list items functions
 */
export async function createServiceListItem(_, listItem) {
    const form = new FormData();
    form.append('service_id', listItem.service_id || '');
    form.append('item_title_en', listItem.item_title_en || '');
    form.append('item_title_de', listItem.item_title_de || '');
    form.append('item_description_en', listItem.item_description_en || '');
    form.append('item_description_de', listItem.item_description_de || '');


    listItem = form;
    return await axiosClient.post('/admin/services/list/item/add', listItem)
}

export async function getServiceListItems({ commit }, serviceId) {
    commit('setServiceListItems', [true])

    return await axiosClient.get(`/admin/services/${serviceId}/list/items`)
        .then((response) => {
            commit('setServiceListItems', [false, response.data])
        })
        .catch(() => {
            commit('setServiceListItems', [false])
        })
}

export async function getServiceListItem(_, id) {
    return await axiosClient.get(`/admin/services/list/${id}/item`)
}

export function updateServiceListItem(_, listItem) {

    const id = listItem.id
    const form = new FormData();
    form.append('id', listItem.id);
    form.append('item_title_en', listItem.item_title_en || '');
    form.append('item_title_de', listItem.item_title_de || '');
    form.append('item_description_en', listItem.item_description_en || '');
    form.append('item_description_de', listItem.item_description_de || '');

    form.append('_method', 'PUT');
    listItem = form;

    return axiosClient.post(`/admin/services/list/${id}/item/update`, listItem)

}

export function deleteServiceListItem(_, listItem) {
    return axiosClient.delete(`/admin/services/list/${listItem.id}/item/delete`)
}


/**
 * Gain functions
 */
export async function getGainInfo() {
    return await axiosClient.get('/admin/gain/info')

}

export function updateGainInfo(_, gainInfo) {

    const form = new FormData();

    form.append('gain_section_title_en', gainInfo.gain_section_title_en || '');
    form.append('gain_section_title_de', gainInfo.gain_section_title_de || '');

    form.append('page_title_en', gainInfo.page_title_en || '');
    form.append('page_title_de', gainInfo.page_title_de || '');

    form.append('content_title_en', gainInfo.content_title_en || '');
    form.append('content_title_de', gainInfo.content_title_de || '');

    form.append('top_text_en', gainInfo.top_text_en || '');
    form.append('top_text_de', gainInfo.top_text_de || '');

    form.append('bottom_text_en', gainInfo.bottom_text_en || '');
    form.append('bottom_text_de', gainInfo.bottom_text_de || '');


    if (gainInfo.gain_page_image instanceof File) {
        form.append('gain_page_image', gainInfo.gain_page_image);
    } else {
        form.append('gain_page_image', '');
    }

    if (gainInfo.gain_section_image instanceof File) {
        form.append('gain_section_image', gainInfo.gain_section_image);
    } else {
        form.append('gain_section_image', '');
    }

    if (gainInfo.small_gain_section_image instanceof File) {
        form.append('small_gain_section_image', gainInfo.small_gain_section_image);
    } else {
        form.append('small_gain_section_image', '');
    }


    form.append('_method', 'PUT');
    gainInfo = form;

    return axiosClient.post(`/admin/gain/update`, gainInfo)
}


/**
 * Gain list items functions
 */
export async function createGainListItem(_, listItem) {
    const form = new FormData();
    form.append('gain_id', listItem.gain_id || '');
    form.append('item_title_en', listItem.item_title_en || '');
    form.append('item_title_de', listItem.item_title_de || '');
    form.append('item_description_en', listItem.item_description_en || '');
    form.append('item_description_de', listItem.item_description_de || '');


    listItem = form;
    return await axiosClient.post('/admin/gain/list/item/add', listItem)
}

export async function getGainListItems({ commit }, gainId) {
    commit('setGainListItems', [true])

    return await axiosClient.get(`/admin/gain/${gainId}/list/items`)
        .then((response) => {
            commit('setGainListItems', [false, response.data])
        })
        .catch(() => {
            commit('setGainListItems', [false])
        })
}

export async function getGainListItem(_, id) {
    return await axiosClient.get(`/admin/gain/list/${id}/item`)
}

export function updateGainListItem(_, listItem) {
    const id = listItem.id
    const form = new FormData();
    form.append('id', listItem.id);
    form.append('item_title_en', listItem.item_title_en || '');
    form.append('item_title_de', listItem.item_title_de || '');
    form.append('item_description_en', listItem.item_description_en || '');
    form.append('item_description_de', listItem.item_description_de || '');

    form.append('_method', 'PUT');
    listItem = form;

    return axiosClient.post(`/admin/gain/list/${id}/item/update`, listItem)

}

export function deleteGainListItem(_, listItem) {
    return axiosClient.delete(`/admin/gain/list/${listItem.id}/item/delete`)
}


/**
 * About Us functions
 */
export async function getAboutInfo() {
    return await axiosClient.get('/admin/about/info')

}

export function updateAboutInfo(_, aboutInfo) {

    const form = new FormData();


    form.append('page_title_en', aboutInfo.page_title_en || '');
    form.append('page_title_de', aboutInfo.page_title_de || '');

    form.append('top_text_en', aboutInfo.top_text_en || '');
    form.append('top_text_de', aboutInfo.top_text_de || '');


    if (aboutInfo.about_page_image instanceof File) {
        form.append('about_page_image', aboutInfo.about_page_image);
    } else {
        form.append('about_page_image', '');
    }


    form.append('_method', 'PUT');
    aboutInfo = form;

    return axiosClient.post(`/admin/about/update`, aboutInfo)
}


/**
 * About Us sections functions
 */
export async function createAboutSection(_, aboutSection) {
    const form = new FormData();
    form.append('section_title_en', aboutSection.section_title_en || '');
    form.append('section_title_de', aboutSection.section_title_de || '');

    form.append('section_description_en', aboutSection.section_description_en || '');
    form.append('section_description_de', aboutSection.section_description_de || '');

    form.append('priority', aboutSection.priority || '');
    form.append('has_items', aboutSection.has_items);


    if (aboutSection.about_section_image instanceof File) {
        form.append('about_section_image', aboutSection.about_section_image);
    } else {
        form.append('about_section_image', '');
    }


    aboutSection = form;
    return await axiosClient.post('/admin/about-us-sections', aboutSection)
}

export async function getAboutSections({ commit }) {
    commit('setAboutSections', [true])

    return await axiosClient.get('/admin/about-us-sections')
        .then((response) => {
            commit('setAboutSections', [false, response.data])
        })
        .catch(() => {
            commit('setAboutSections', [false])
        })
}

export async function getAboutSection(_, id) {
    return await axiosClient.get(`/admin/about-us-sections/${id}`)
}

export function updateAboutSection(_, aboutSection) {

    const id = aboutSection.id
    const form = new FormData();
    form.append('id', aboutSection.id);

    form.append('section_title_en', aboutSection.section_title_en || '');
    form.append('section_title_de', aboutSection.section_title_de || '');

    form.append('section_description_en', aboutSection.section_description_en || '');
    form.append('section_description_de', aboutSection.section_description_de || '');

    form.append('priority', aboutSection.priority || '');
    form.append('has_items', aboutSection.has_items);

    if (aboutSection.about_section_image instanceof File) {
        form.append('about_section_image', aboutSection.about_section_image);
    } else {
        form.append('about_section_image', '');
    }

    form.append('_method', 'PUT');
    aboutSection = form;

    return axiosClient.post(`/admin/about-us-sections/${id}`, aboutSection)

}


/**
 * About Us Section list items functions
 */
export async function createSectionListItem(_, listItem) {
    const form = new FormData();
    form.append('about_us_section_id', listItem.about_us_section_id || '');
    form.append('item_title_en', listItem.item_title_en || '');
    form.append('item_title_de', listItem.item_title_de || '');
    form.append('item_description_en', listItem.item_description_en || '');
    form.append('item_description_de', listItem.item_description_de || '');


    listItem = form;
    return await axiosClient.post('/admin/about-us-sections/list/item/add', listItem)
}

export async function getSectionListItems({ commit }, sectionId) {
    commit('setSectionListItems', [true])

    return await axiosClient.get(`/admin/about-us-sections/${sectionId}/list/items`)
        .then((response) => {
            console.log(response);
            commit('setSectionListItems', [false, response.data])
        })
        .catch(() => {
            commit('setSectionListItems', [false])
        })
}

export async function getSectionListItem(_, id) {
    return await axiosClient.get(`/admin/about-us-sections/list/${id}/item`)
}

export function updateSectionListItem(_, listItem) {

    const id = listItem.id
    const form = new FormData();
    form.append('id', listItem.id);
    form.append('item_title_en', listItem.item_title_en || '');
    form.append('item_title_de', listItem.item_title_de || '');
    form.append('item_description_en', listItem.item_description_en || '');
    form.append('item_description_de', listItem.item_description_de || '');

    form.append('_method', 'PUT');
    listItem = form;

    return axiosClient.post(`/admin/about-us-sections/list/${id}/item/update`, listItem)

}

export function deleteSectionListItem(_, listItem) {
    return axiosClient.delete(`/admin/about-us-sections/list/${listItem.id}/item/delete`)
}


/**
 * Team functions
 */
export async function createTeam(_, team) {
    const form = new FormData();
    form.append('name_en', team.name_en || '');
    form.append('name_de', team.name_de || '');

    form.append('position_en', team.position_en || '');
    form.append('position_de', team.position_de || '');

    form.append('description_en', team.description_en || '');
    form.append('description_de', team.description_de || '');

    form.append('status', team.status);


    if (team.team_image instanceof File) {
        form.append('team_image', team.team_image);
    } else {
        form.append('team_image', '');
    }


    team = form;
    return await axiosClient.post('/admin/teams', team)
}

export async function getTeamMembers({ commit }) {
    commit('setTeamMembers', [true])

    return await axiosClient.get('/admin/teams')
        .then((response) => {
            commit('setTeamMembers', [false, response.data])
        })
        .catch(() => {
            commit('setTeamMembers', [false])
        })
}

export async function getTeamMember(_, id) {
    return await axiosClient.get(`/admin/teams/${id}`)
}

export function updateTeam(_, team) {

    const id = team.id
    const form = new FormData();
    form.append('id', team.id);

    form.append('name_en', team.name_en || '');
    form.append('name_de', team.name_de || '');

    form.append('position_en', team.position_en || '');
    form.append('position_de', team.position_de || '');

    form.append('description_en', team.description_en || '');
    form.append('description_de', team.description_de || '');


    form.append('status', team.status);

    if (team.team_image instanceof File) {
        form.append('team_image', team.team_image);
    } else {
        form.append('team_image', '');
    }

    form.append('_method', 'PUT');
    team = form;

    return axiosClient.post(`/admin/teams/${id}`, team)

}



/**
 * Settings functions
 */
export async function getSettings() {
    return await axiosClient.get('/admin/settings/info')

}

export function updateSettings(_, settings) {

    const form = new FormData();

    form.append('phone', settings.phone || '');
    form.append('email', settings.email || '');
    form.append('address', settings.address || '');

    form.append('privacy_text_en', settings.privacy_text_en || '');
    form.append('privacy_text_de', settings.privacy_text_de || '');

    form.append('imprint_text_en', settings.imprint_text_en || '');
    form.append('imprint_text_de', settings.imprint_text_de || '');


    if (settings.imprint_image instanceof File) {
        form.append('imprint_image', settings.imprint_image);
    } else {
        form.append('imprint_image', '');
    }

    if (settings.privacy_image instanceof File) {
        form.append('privacy_image', settings.privacy_image);
    } else {
        form.append('privacy_image', '');
    }


    form.append('_method', 'PUT');
    settings = form;

    return axiosClient.post(`/admin/settings/update`, settings)
}


/**
 * Profile functions
 */
export async function getCurrentUser({ commit }, data) {
    return await axiosClient.get('/admin/user/info', data)
        .then(({ data }) => {
            commit('setUser', data.user);
            return data;
        })
}

export function updateProfileInfo(_, { user }) {

    const form = new FormData();

    form.append('name', user.value.name);
    form.append('email', user.value.email);

    form.append('_method', 'PUT');
    user = form;

    return axiosClient.post('/admin/profile/edit', user)
}

export async function changePassword(_, passwordData) {
    return await axiosClient.put('/admin/change-password', passwordData);
}



