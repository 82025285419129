<script setup>
import useOurTeam from "../../use/useOurTeam"
import { onUpdated, defineProps, ref, defineEmits } from "vue"

const props = defineProps({
  modelValue: Boolean,
  team: {
    required: true,
    type: Object,
  },
})

const teamData = ref({
  id: props.team.id,
  
  name_en: props.team.name_en,
  name_de: props.team.name_de,
  position_en: props.team.position_en,
  position_de: props.team.position_de,
  description_en: props.team.description_en,
  description_de: props.team.description_de,
  status: props.team.status,
  team_image: props.team.team_image,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, errors, onSubmit, closeModal } = useOurTeam(
  props,
  emit,
  teamData
)

onUpdated(() => {
  teamData.value = {
    id: props.team.id,
   
    name_en: props.team.name_en,
    name_de: props.team.name_de,
    position_en: props.team.position_en,
    position_de: props.team.position_de,
    description_en: props.team.description_en,
    description_de: props.team.description_de,
    status: props.team.status,
    team_image: props.team.team_image,
  }
})

const onTeamImageChange = (event) => {
  teamData.value.team_image = event.target.files[0]
}

</script>

<template>
  <!-- add team's member modal  -->
  <BModal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="Abschnitt hinzufügen"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    size="lg"
    centered
    no-close-on-backdrop
  >
    <BOverlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <BCard>
        <BForm
          id="addform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
          enctype="multipart/form-data"
        >
          <div class="row">
            <div class="col-6">
              <div class="mb-3">
                <label for="name_en" class="form-label"
                  >Abschnittstitel (en)</label
                >
                <input
                  type="text"
                  id="name_en"
                  class="form-control"
                  placeholder="Abschnittstitel (en)"
                  v-model="teamData.name_en"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.name_en"
                  >
                    <span>{{ errors.name_en[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="name_de" class="form-label"
                  >Abschnittstitel (de)</label
                >
                <input
                  type="text"
                  id="name_de"
                  class="form-control"
                  placeholder="Abschnittstitel (de)"
                  v-model="teamData.name_de"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.name_de"
                  >
                    <span>{{ errors.name_de[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="mb-3">
                <label for="position_en" class="form-label"
                  >Abschnittstitel (en)</label
                >
                <input
                  type="text"
                  id="position_en"
                  class="form-control"
                  placeholder="Abschnittstitel (en)"
                  v-model="teamData.position_en"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.position_en"
                  >
                    <span>{{ errors.position_en[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="position_de" class="form-label"
                  >Abschnittstitel (de)</label
                >
                <input
                  type="text"
                  id="position_de"
                  class="form-control"
                  placeholder="Abschnittstitel (de)"
                  v-model="teamData.position_de"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.position_de"
                  >
                    <span>{{ errors.position_de[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            

            <div class="col-12">
              <div class="mb-3">
                <label for="description_en" class="form-label"
                  >Abschnittsbeschreibung (en)</label
                >
                <textarea
                  class="form-control"
                  id="description_en"
                  placeholder="Abschnittsbeschreibung (en)"
                  rows="2"
                  v-model="teamData.description_en"
                ></textarea>
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.description_en">
                    <span>{{ errors.description_en[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label for="description_de" class="form-label"
                  >Abschnittsbeschreibung (de)</label
                >
                <textarea
                  class="form-control"
                  id="description_de"
                  placeholder="Abschnittsbeschreibung (de)"
                  rows="2"
                  v-model="teamData.description_de"
                ></textarea>
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.description_de">
                    <span>{{ errors.description_de[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-3">
                <label for="status" class="form-label"
                  >verfügt über Artikel</label
                >
                <select class="form-select" id="status" v-model="teamData.status">
                  <option value="0">NEIN</option>
                  <option value="1">Ja</option>
                  </select>
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.status"
                  >
                    <span>{{ errors.status[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-3">
                <label for="team_image" class="form-label"
                  >Bild des About-Bereichs</label
                >
                <input
                  class="form-control"
                  type="file"
                  id="team_image"
                  @change="onTeamImageChange"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.team_image"
                  >
                    <span>{{ errors.team_image[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <BButton
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
              schließen
            </BButton>
            <BButton type="submit" variant="success">
              Abschnitt hinzufügen
            </BButton>
          </div>
        </BForm>
      </BCard>
    </BOverlay>
  </BModal>
</template>
