<script setup>
import { onMounted, ref } from "vue"

import Layout from "@/layouts/main.vue"

import store from "../../state/store"
import AboutInfoForm from "./about-info-form.vue"
import AboutUsSections from "./about-us-sections.vue"
import OurTeam from "./our-team.vue"


const DEFAULT_ABOUT = {
  id: "",
  page_title_en: "",
  page_title_de: "",
  top_text_en: "",
  top_text_de: "",
  about_page_image: "",
}

const aboutModel = ref({ ...DEFAULT_ABOUT })


onMounted(async () => {
  const data = await store.dispatch("getAboutInfo")
  aboutModel.value = {
    ...data.data.data,
  }
})
</script>

<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img
          :src="aboutModel.about_page_image"
          class="profile-wid-img"
          alt=""
        />
        <div class="overlay-content">
          <h1 class="p-4 text-start text-light">
            {{ aboutModel.page_title_de }}
          </h1>
        </div>
      </div>
    </div>

    <BRow>
      <BCol xxl="12">
        <BCard no-body class="mt-xxl-n5">
          <BCardBody class="p-4 pt-2">
            <BTabs nav-class="nav-tabs-custom rounded border-bottom-0">
              <BTab class="nav-item" title="Über uns Informationen" active>
                <AboutInfoForm :aboutModel="aboutModel" />
              </BTab>

              <BTab title="Abschnitte">
                <AboutUsSections />
              </BTab>

              <BTab title="Unser Team">
                <OurTeam />
              </BTab>
            </BTabs>
          </BCardBody>
        </BCard>
      </BCol>
    </BRow>
  </Layout>
</template>

<style scoped>
.profile-wid-bg::before {
  opacity: 0.75;
}

.profile-setting-img .overlay-content {
  left: 0;
}
</style>
