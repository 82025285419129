import { ref, computed } from "vue";
import { useToast } from "vue-toastification"

import store from "../state/store"


export default function useAboutSections(props, emit, aboutSection) {

    // Get toast interface
    const toast = useToast()

    const loading = ref(false)
    const errors = ref({})


    const show = computed({
        get: () => props.modelValue,
        set: (value) => emit("update:modelValue", value),
    })

    const getAboutSections = () => {
        store.dispatch("getAboutSections")
    }

    const onSubmit = () => {
        loading.value = true

        if (aboutSection.value.id) {
            store.dispatch('updateAboutSection', aboutSection.value)
                .then(response => {
                    loading.value = false;
                    if (response.status === 200) {
                        toast.success("Der Dienst wurde erfolgreich aktualisiert!", {
                            timeout: 3000,
                        })
                        store.dispatch("getAboutSections")
                        closeModal()
                    }
                })
                .catch((err) => {
                    loading.value = false
                    errors.value = err.response.data.errors
                })

        } else {

            store
                .dispatch("createAboutSection", aboutSection.value)
                .then((response) => {
                    loading.value = false
                    if (response.status === 201) {
                        toast.success("Dienst erfolgreich hinzugefügt!", {
                            timeout: 3000,
                        })
                        store.dispatch("getAboutSections")
                        closeModal()
                    }
                })
                .catch((err) => {
                    loading.value = false
                    errors.value = err.response.data.errors
                })
        }

    }

    const closeModal = () => {
        show.value = false
        errors.value = {}
        aboutSection.value.about_section_image = null
        emit("close")
    }


    return {
        show,
        loading,
        errors,
        getAboutSections,
        onSubmit,
        closeModal,
    }
}