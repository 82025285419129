<script setup>
import { ref } from "vue"
import { useRoute } from "vue-router"

import Layout from "@/layouts/main.vue"
import PageHeader from "@/components/page-header"

import store from "../../state/store"
import SectionListItemTable from "./section-list-items-table.vue"
import SectionListItemModal from "./section-list-item-modal.vue"
import SectionListItemEditModal from "./section-list-item-edit-modal.vue"

const title = "Elemente in der Abschnittsliste"
const pageTitle = "Armaturenbrett"

const route = useRoute()
const sectionId = route.params.sectionId


const DEFAULT_LIST_ITEM = {
  id: "",
  about_us_section_id: "",
  section_title_en: "",
  section_title_de: "",
  item_title_en: "",
  item_title_de: "",
  item_description_de: "",
  item_description_en: "",
}

const listItemModel = ref({ ...DEFAULT_LIST_ITEM })
const showListItemModal = ref(false)
const showListItemEditModal = ref(false)

const showAddNewModal = () => {
  showListItemModal.value = true
}

const showEditModal = () => {
  showListItemEditModal.value = true
}

function editListItem(listItem) {
  store.dispatch("getSectionListItem", listItem.id).then(({ data }) => {
    listItemModel.value = data.data

    console.log(listItemModel.value);
    showEditModal()
  })
}

const onModalClose = () => {
  listItemModel.value = { ...DEFAULT_LIST_ITEM }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />

    <BRow>
      <BCol lg="12">
        <BCard no-body id="sectionListItems">
          <BCardHeader class="border-bottom-dashed">
            <BRow class="g-4 align-items-center">
              <BCol sm>
                <div>
                  <h5 class="card-title mb-0">
                    Liste der Elemente der Abschnittsliste
                  </h5>
                </div>
              </BCol>
              <BCol sm="auto">
                <div class="d-flex flex-wrap align-items-start gap-2">
                  <BButton
                    type="button"
                    variant="success"
                    class="add-btn me-1"
                    id="create-btn"
                    @click="showAddNewModal()"
                  >
                    <i class="ri-add-line align-bottom me-1"></i> Füge neuen
                    Gegenstand hinzu
                  </BButton>
                </div>
              </BCol>
            </BRow>
          </BCardHeader>

          <!-- section list items table  -->

          <SectionListItemTable
            :sectionId="sectionId"
            @clickEdit="editListItem"
          />

          <!-- add and edit section list item modal  -->

          <SectionListItemModal
            v-model="showListItemModal"
            :sectionId="sectionId"
            :listItem="listItemModel"
            @close="onModalClose"
          />

          <SectionListItemEditModal
            v-model="showListItemEditModal"
            :listItem="listItemModel"
            @close="onModalClose"
          />
        </BCard>
      </BCol>
    </BRow>
  </Layout>
</template>
