import { ref, computed } from "vue";
import { useToast } from "vue-toastification"

import store from "../state/store"
import useDelete from './useDelete';


export default function useServiceListItems(props, emit, listItem) {

    // Get toast interface
    const toast = useToast()

    const loading = ref(false)
    const errors = ref({})

    const show = computed({
        get: () => props.modelValue,
        set: (value) => emit("update:modelValue", value),
    })

    //!! Has this function been used anywhere?

    const getServiceListItems = async () => {
        try {
            await store.dispatch("getServiceListItems", props.serviceId)
        } catch (error) {
            console.error(error)
            return null
        }
    }

    const onSubmit = () => {
        loading.value = true
        if (listItem.value.id) {
            store.dispatch('updateServiceListItem', listItem.value)
                .then(response => {
                    loading.value = false;
                    if (response.status === 200) {
                        toast.success("Erfolgreich aktualisiert!", {
                            timeout: 3000,
                        })
                        store.dispatch("getServiceListItems", response.data.data.service_id)
                        closeModal()
                    }
                })
                .catch((err) => {
                    loading.value = false
                    errors.value = err.response.data.errors
                })

        } else {

            store
                .dispatch("createServiceListItem", listItem.value)
                .then((response) => {
                    loading.value = false
                    if (response.status === 201) {
                        toast.success("Erfolgreich hinzugefügt!", {
                            timeout: 3000,
                        })

                        store.dispatch("getServiceListItems", response.data.data.service_id)
                        closeModal()
                    }
                })
                .catch((err) => {
                    loading.value = false
                    errors.value = err.response.data.errors
                })
        }

    }

    const closeModal = () => {
        show.value = false
        errors.value = {}
        emit("close")
    }

    const deleteServiceListItem = (listItem) => {
        const { confirmDelete } = useDelete(
            () => store.dispatch("deleteServiceListItem", listItem),
            'Erfolgreich entfernt!',
            getServiceListItems
        );

        confirmDelete();
    };

    return {
        show,
        loading,
        errors,
        getServiceListItems,
        onSubmit,
        closeModal,
        deleteServiceListItem,
    }
}