const ROLE = {
    ADMIN: 'admin',
};

export function setUser(state, user) {
    if (user) {
        state.user.data = user;
        state.user.loggedIn = true,
            state.user.isAdmin = user.ur === ROLE.ADMIN;

        // Save user data to localStorage
        localStorage.setItem('USER', JSON.stringify(state.user.data));

        if (state.user.isAdmin) {
            localStorage.setItem('USERROLE', 'Admin');
        }
        localStorage.setItem('LOGGEDIN', state.user.loggedIn)
    } else {
        // Clear user data from localStorage
        localStorage.removeItem('USER');
        localStorage.removeItem('USERROLE');
        localStorage.removeItem('LOGGEDIN');
    }
}

export function setToken(state, token) {
    state.user.token = token;
    if (token) {
        localStorage.setItem('TOKEN', token);
    } else {
        localStorage.removeItem('TOKEN')
    }
}

export function setServices(state, [loading, data = null]) {
    if (data) {
        state.services = {
            ...state.services,
            data: data.data,
        }

    }

    state.services.loading = loading;
}

export function setServiceListItems(state, [loading, data = null]) {
    if (data) {
        state.serviceListItems = {
            ...state.serviceListItems,
            data: data.data,
        }

    }

    state.serviceListItems.loading = loading;
}

export function setGainListItems(state, [loading, data = null]) {
    if (data) {
        state.gainListItems = {
            ...state.gainListItems,
            data: data.data,
        }

    }

    state.gainListItems.loading = loading;
}

export function setAboutSections(state, [loading, data = null]) {
    if (data) {
        state.aboutSections = {
            ...state.aboutSections,
            data: data.data,
        }

    }

    state.aboutSections.loading = loading;
}

export function setSectionListItems(state, [loading, data = null]) {
    if (data) {
        state.sectionListItems = {
            ...state.sectionListItems,
            data: data.data,
        }

    }

    state.sectionListItems.loading = loading;
}

export function setTeamMembers(state, [loading, data = null]) {
    if (data) {
        state.team = {
            ...state.team,
            data: data.data,
        }

    }

    state.team.loading = loading;
}